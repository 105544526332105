import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import FAQAccordionCard from "../components/FAQAccordionCard";
import { fetchFaqsAsync } from "../features/faq/faqSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const faqData = [
  {
    question: "ما هو تصنيف الدورات التعليمية؟",
    answer:
      "يمكن تصنيف الدورات التعليمية حسب مستوى الصعوبة، الموضوع، أو الهدف التعليمي.",
  },
  {
    question: "كيف يمكنني التسجيل في دورة تعليمية؟",
    answer:
      "للتسجيل في دورة تعليمية، يجب عليك التوجه إلى صفحة التسجيل على الموقع الإلكتروني وملء النموذج المطلوب بالمعلومات الصحيحة.",
  },
  {
    question: "هل هناك تكلفة للدورات التعليمية؟",
    answer:
      "قد تكون هناك تكلفة لبعض الدورات التعليمية، ولكن يمكن أن تكون هناك أيضًا دورات مجانية متاحة.",
  },
  {
    question: "كيف يمكنني شراء دورة تعليمية؟",
    answer:
      "يمكنك شراء دورة تعليمية عن طريق الانتقال إلى صفحة الدورة واتباع التعليمات اللازمة لإتمام عملية الشراء.",
  },
  {
    question: "ما هي طرق الدفع المتاحة؟",
    answer:
      "نحن نقبل الدفع عبر بطاقات الائتمان الرئيسية مثل Visa وMasterCard وبطاقات الخصم المباشر.",
  },
  {
    question: "هل يمكنني استرداد أموالي إذا لم تناسبني الدورة؟",
    answer:
      "نعم، يمكنك طلب استرداد أموالك في غضون فترة معينة بعد شراء الدورة إذا لم تكن راضيًا عنها. يُرجى الرجوع إلى شروط وأحكام الاسترداد لمزيد من المعلومات.",
  },
  {
    question: "كم من الوقت يستغرق استرداد الأموال؟",
    answer:
      "يتم معالجة طلبات الاسترداد خلال فترة زمنية محددة، ويمكن أن يستغرق وصول المبلغ المسترد إلى حسابك بعد ذلك حسب سياسة البنك ووسيلة الدفع المستخدمة.",
  },
];

export default function FAQPage() {
  let faqs = useSelector((state) => state.faq.faq);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFaqsAsync());
  }, [dispatch]);
  let sz = faqs.length;

  return (
    <div>
      <BreadCrumb label={"الأسئلة الشائعة"} />
      <div class="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap">
        <div class="container">
          <div class="section-title text-end mb--60 col-lg-12">
            <h4 class="title">يسعدنا أن نجيب على أكثر الأسئلة والاستفسارات شيوعاً </h4>
          </div>
          <div class="row g-5">
            <div class="rbt-accordion-style accordion col-lg-6 col-sm-12">
              <div class="rbt-accordion-style rbt-accordion-04 accordion ">
                <div class="accordion" id="accordionExamplec3">
                  {faqs.slice(0, (sz + 1) / 2).map((faq, index) => (
                    <FAQAccordionCard faq={faq} index={index} />
                  ))}
                </div>
              </div>
            </div>
            <div class="rbt-accordion-style accordion col-lg-6 col-sm-12">
              <div class="rbt-accordion-style rbt-accordion-04 accordion">
                <div class="accordion" id="faqs-accordionExamplec3">
                  {faqs.slice(parseInt(-sz / 2)).map((faq, index) => (
                    <FAQAccordionCard
                      faq={faq}
                      index={index + parseInt((sz + 1) / 2)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
