import React from "react";
import AboutFeature from "../components/AboutFeature";
import { Link } from "react-router-dom";

export default function AboutSection() {
  return (
    <div
      style={{ marginBottom: "3rem" }}
      class="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop"
    >
      <div class="container">
        <div class="row g-5 ">
          <div class="col-lg-6">
            <div class="thumbnail-wrapper">
              <div class="thumbnail image-1">
                <img
                  data-parallax='{"x": 0, "y": -20}'
                  src="assets/images/about/about-08.png"
                  alt="Education Images"
                />
              </div>
              <div class="thumbnail image-2 d-none d-xl-block">
                <img
                  data-parallax='{"x": 0, "y": 60}'
                  src="assets/images/about/about-09.png"
                  alt="Education Images"
                />
              </div>
              <div class="thumbnail image-3 d-none d-md-block">
                <img
                  data-parallax='{"x": 0, "y": 80}'
                  src="assets/images/about/about-07.jpg"
                  alt="Education Images"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            
            <div class="inner pl--50 pl_sm--0 pl_md--0">
              <div class="section-title text-end">
                <span class="subtitle bg-coral-opacity">عن الأكاديمية</span>
                <h2 class="title">أكاديمية مصرية خاصة</h2>
              </div>
              <p class="description mt--30">
                تقدم برامجها وأنشطتها العلمية
                والتكنولوجية في مجال رسالتها التي تتبناها منذ عام 1983" التنمية
                العلمية والتكنولوجية المبكرة للأطفال والنشء" والحاصلة على اعتماد
                المجلس الوطني للتدريب والتعليم وعضو بشعبة مراكز تنمية وتدريب
                الموارد البشرية بالاتحاد العام للغرف التجارية.
              </p>

              <div class="rbt-feature-wrapper mt--40">
                <AboutFeature
                  icon={"feather-target"}
                  color="bg-pink-opacity"
                  title={"الهدف الاستراتيجي"}
                  description={
                    "إكساب الأطفال والنـشء مبكراً المعرفة العلمية والإنسانية والمهارات التكنولوجية والحياتية الأساسية والمناسبة لاحتياجاتهم العمرية وقدراتهم الذاتية علاوة على إكسابهم مهارات التفكير العلمي والإنساني."
                  }
                />
                  <AboutFeature
                    icon={"feather-eye"}
                    color="bg-coral-opacity"
                    title={"الرؤية"}
                    description={
                      "تسعى الأكاديمية لتكون الرائدة في تقديم البرامج التعليمية والتدريبية المتميزة والمتطورة في مجالات التنمية العلمية والتكنولوجية المبكرة للأطفال والنشء."
                    }
                  />
                <AboutFeature
                  icon={"feather-star"}
                  color="bg-primary-opacity"
                  title={"تتميز الأكاديمية..."}
                  description={
                    "بتقديم برامج متنوعة ومرنة، وتضم مجموعة من المحاضرين المحترفين والمتخصصين في مجالات متعددة. توفر ايضا بيئة تعليمية محفزة وتفاعلية تساعد الطلاب على تطوير مهاراتهم وتحقيق أهداف الاكاديمية."
                  }
                />
              </div>

              <div class="about-btn mt--40">
                <Link
                  class="rbt-btn btn-gradient hover-icon-reverse"
                  to="/about"
                >
                  <span class="icon-reverse-wrapper">
                    <span class="btn-text">تصفح المزيد</span>
                    <span class="btn-icon">
                      <i class="feather-arrow-left"></i>
                    </span>
                    <span class="btn-icon">
                      <i class="feather-arrow-left"></i>
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
