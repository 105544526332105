import { configureStore } from "@reduxjs/toolkit";
import programsReducer from "../features/programs/programsSlice";
import jobsReducer from "../features/jobs/jobsSlice";
import blogsReducer from "../features/blogs/blogsSlice";
import faqReducer from "../features/faq/faqSlice";
import authReducer from "../features/auth/authSlice";
import programRegistrationsReducer from "../features/programRegistration/programRegistrationSlice";
import applicantReducer from "../features/applicant/applicantSlice";
export const store = configureStore({
  reducer: {
    programs: programsReducer,
    jobs: jobsReducer,
    blogs: blogsReducer,
    faq: faqReducer,
    auth: authReducer,
    programRegistrations: programRegistrationsReducer,
    applicant: applicantReducer
  },
});
