import React, { useState, useEffect } from "react";
import ArrayInput from "../ArrayInputs/ArrayInput";
import ImagePicker from "../ArrayInputs/ImagePicker";
import {
  fetchBlogsAsync,
  createBlogAsync,
  deleteBlogAsync,
  updateBlogAsync,
} from "../../features/blogs/blogsSlice";
import { useSelector } from "react-redux";

export default function BlogForm({
  blog,
  updateBlog,
  deleteBlog,
  addBlog,
  blogs,
  isNew,
  dispatch,
  index,
  toggleError,
  toggleSuccess,
}) {
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [creating, setCreating] = useState(false);
  const status = useSelector((state) => state.blogs.status);
  const [operation, setOperation] = useState(false);
  useEffect(() => {
    if (operation) {
      if (status === "succeeded") {
        toggleSuccess();
        dispatch(fetchBlogsAsync());
        setOperation(false);
      } else if (status === "failed") {
        toggleError();
        setOperation(false);
      }
    }
  }, [status, operation, dispatch]);
  function handleBlogUpdate() {
    setOperation(true);
    dispatch(updateBlogAsync(blog));
  }
  function handleBlogDelete() {
    if (!window.confirm("هل تريد مسح المدونة؟")) return;
    setOperation(true);
    dispatch(deleteBlogAsync(blog.id));
  }
  function handleBlogAdd() {
    let temp = { ...blog };
    // date format: 2024-05-04T10:34:55.448Z
    temp.publishDate = new Date().toISOString();
    setOperation(true);
    dispatch(createBlogAsync(temp));
    // clear the form
    updateBlog({
      title: "",
      subtitle: "",
      content: "",
      publishDate: "2021-10-10",
      tags: [],
      titlePhoto: "/assets/images/course/course-online-02.jpg",
      visible: true,
      id: 0,
    });
  }
  return (
    <form class="row g-5 checkout-form">
      <div class="col-lg-12">
        <div class="checkout-content-wrapper">
          <div id="billing-form">
            <div class="row">
              <ImagePicker
                title="صورة المدونة"
                image={blog.titlePhoto}
                setImage={(titlePhoto) => {
                  updateBlog({ ...blog, titlePhoto });
                }}
                index={index}
                id="titlePhoto"
              />
              <div class="col-md-12 col-12 mb--20">
                <label>عنوان المدونة*</label>
                <input
                  type="text"
                  placeholder="عنوان المدونة"
                  value={blog.title}
                  onChange={(e) => {
                    updateBlog({ ...blog, title: e.target.value });
                  }}
                />
              </div>

              <div class="col-12 mb--20">
                <label>محتوى المدونة*</label>
                <textarea
                  type="text"
                  placeholder="محتوى المدونة"
                  rows={4}
                  value={blog.content}
                  onChange={(e) =>
                    updateBlog({ ...blog, content: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="col-12 mb--20">
                <label>الكلمات المفتاحية*</label>
                <ArrayInput
                  title="الكلمات المفتاحية"
                  list={blog.tags}
                  setList={(nwtags) => updateBlog({ ...blog, tags: nwtags })}
                  row={1}
                  shown={3}
                />
              </div>

              <div class="col-12 mb--20" style={{ float: "right" }}>
                <div class="check-box">
                  <input
                    type="checkbox"
                    id={`visible${index}`}
                    checked={blog.visible}
                    onChange={(e) =>
                      updateBlog({ ...blog, visible: e.target.checked })
                    }
                  />
                  <label htmlFor={`visible${index}`}>ظاهر</label>
                </div>
              </div>
              <div class="rbt-button-group">
                {isNew ? (
                  <button
                    class="rbt-btn"
                    type="button"
                    onClick={handleBlogAdd}
                    disabled={creating}
                  >
                    {creating ? "جاري الاضافة" : "اضافة"}
                  </button>
                ) : (
                  <>
                    <button
                      class="rbt-btn"
                      type="button"
                      onClick={handleBlogUpdate}
                      disabled={editing || deleteing}
                    >
                      {editing ? "جاري التعديل" : "تعديل"}
                    </button>
                    <button
                      class="rbt-btn btn-secondary"
                      type="button"
                      onClick={handleBlogDelete}
                      disabled={editing || deleteing}
                    >
                      {deleteing ? "جاري المسح" : "مسح"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
