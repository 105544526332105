import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div>
      <header className="rbt-header rbt-header-10">
        <div className="rbt-sticky-placeholder"></div>
        <div className="rbt-header-wrapper header-space-betwween header-transparent header-sticky">
          <div className="container-fluid">
            <div className="mainbar-row rbt-navigation-start align-items-center">
              <div className="header-left rbt-header-content">
                <div className="header-info">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="/assets/images/logo/logo.png"
                        alt="Education Logo Images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="rbt-main-navigation d-none d-xl-block">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li className="has-dropdown has-menu-child-item">
                      <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                        عن الأكاديمية
                      </Link>
                    </li>
                    <li className="has-dropdown has-menu-child-item">
                      <Link
                        to="/programs"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        البرامج
                      </Link>
                    </li>
                    <li className="has-dropdown has-menu-child-item">
                      <Link to="/blogs" onClick={() => window.scrollTo(0, 0)}>
                        المدونة
                      </Link>
                    </li>
                    <li className="has-dropdown has-menu-child-item">
                      <Link to="/faq" onClick={() => window.scrollTo(0, 0)}>
                        الأسئلة الشائعة
                      </Link>
                    </li>
                    <li className="has-dropdown has-menu-child-item">
                      <Link to="/careers" onClick={() => window.scrollTo(0, 0)}>
                        الوظائف
                      </Link>
                    </li>
                    <li className="has-dropdown has-menu-child-item">
                      <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>
                        تواصل معنا
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="rbt-btn-wrapper d-none d-xl-block">
                  <Link
                    className="rbt-btn rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
                    to="/programRegistration"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <span data-text="سجل الأن">سجل الأن</span>
                  </Link>
                </div>
                <div className="mobile-menu-bar d-block d-xl-none">
                  <div className="hamberger">
                    <button className="hamberger-button rbt-round-btn">
                      <i className="feather-menu"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="popup-mobile-menu">
        <div className="inner-wrapper">
          <div className="inner-top mb--0">
            <div className="content">
              <div className="logo">
                <a href="index.html">
                  <img
                    src="/assets/images/logo/logo.png"
                    alt="Education Logo Images"
                  />
                </a>
              </div>
              <div className="rbt-btn-close">
                <button className="close-button rbt-round-btn" id="close-menu">
                  <i className="feather-x"></i>
                </button>
              </div>
            </div>
            {/* <p className="description">
              تقدم أكاديمية مهاتك برامجها و أنشطتها العلمية في مجال "التنمية
              العلمية و التكنولوجية المبكرة للأطفال والنشء" .
            </p> */}
            <ul className="navbar-top-left rbt-information-list justify-content-start mt--15">
              <li>
                <a href="#">
                  <i className="feather-phone"></i>
                  <a href="https://wa.me/+201019999357" target="_blank" style={{marginLeft:"3px"}}>
                    01019999357
                  </a>{"  "}
                  -{"  "}
                  <a href="https://wa.me/+201123655002" target="_blank" style={{marginRight:"3px"}}>
                    01123655002
                  </a>
                </a>
              </li>
            </ul>
            <div className="social-share-wrapper">         
              <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                <li>
                  <a href="https://www.facebook.com/">
                    <i className="feather-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com">
                    <i className="feather-twitter"></i>
                  </a>
                </li>
              
                {/* <li>
                  <a href="https://www.linkdin.com/">
                    <i className="feather-linkedin"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <nav className="mainmenu-nav">
            <ul className="mainmenu">
              <Link
                to="/about"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item">
                  عن الأكاديمية
                </li>
              </Link>
              <Link
                to="/programs"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item">برامج</li>
              </Link>
              <Link
                to="/blogs"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item"> المدونة</li>
              </Link>
              <Link
                className="has-dropdown has-menu-child-item"
                to="/faq"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item">
                  الأسئلة الشائعة
                </li>
              </Link>
              <Link
                to="/careers"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item">الوظائف</li>
              </Link>
              <Link
                to="/contact"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <li className="has-dropdown has-menu-child-item">
                  {" "}
                  تواصل معنا
                </li>
              </Link>
            </ul>
          </nav>

          <div className="mobile-menu-bottom">
            <div className="rbt-btn-wrapper mb--20 ">
              <Link
                className="rbt-btn btn-border-gradient  radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                to="/programRegistration"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("close-menu").click();
                }}
              >
                <span>سجل الان</span>
              </Link>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}
