import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

export const fetchPrograms = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/program`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch programs");
  }
};
export const fetchProgram = async (programId) => {
  try {
    const response = await axios.get(`${BASE_URL}/program/${programId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch program");
  }
}
function getFormData(params) {
  let formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if(key === 'gallery'){
      for (let i = 0; i < value.length; i++) {
        let image = value[i];
        formData.append('photos', image);
      }
    }else if (value !== null && value) formData.append(key, value);
  }
  return formData;
}
export const createProgram = async (programData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const response = await axios.post(`${BASE_URL}/program`, getFormData(programData));
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to create program");
  }
};

export const updateProgram = async (programId, updatedProgramData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // axios.defaults.headers.common["Content-Type"] = "multipart/form-data";    
    const response = await axios.put(
      `${BASE_URL}/program/${programId}`,updatedProgramData);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to update program");
  }
};

export const deleteProgram = async (programId) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.delete(`${BASE_URL}/program/${programId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to delete program");
  }
};

