import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

export const fetchJobs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/jobs`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch jobs");
  }
};

export const fetchJob = async (jobId) => {
  try {
    const response = await axios.get(`${BASE_URL}/jobs/${jobId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch job");
  }
};

export const createJob = async (jobData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(`${BASE_URL}/jobs`, jobData);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to create job");
  }
};

export const updateJob = async (jobId, updatedjobData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.put(
      `${BASE_URL}/jobs/${jobId}`,
      updatedjobData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to update job");
  }
};

export const deleteJob = async (jobId) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.delete(`${BASE_URL}/jobs/${jobId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to delete job");
  }
};
