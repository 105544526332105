import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import ProgramForm from "../AdminForms/ProgramForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProgramsAsync,
  createProgramAsync,
} from "../../features/programs/programsSlice";

export default function ProgramList({toggleError, toggleSuccess}) {
  // let programsTemp = [
  //   {
  //     index: 1,
  //     name: "نادي علوم ببساطة",
  //     eligibleAgeFrom: 3,
  //     eligibleAgeTo: 5,
  //     toWho: "للأطفال من (5-3 سنوات) مرحلة ما قبل الروضة",
  //     programType: "ScientificClub",
  //     bref: "للأطفال من (5-3 سنوات) مرحلة ما قبل الروضة",
  //     definition: "برنامج \" ببساطة \"هو أول برنامج وطني لتنمية التفكير العلمي والمنطقي لأطفال ما قبل مرحلة رياض الأطفال من عمر (3) إلى (5) سنوات من خلال تقديم مجموعة من الأنشطة \" برؤية علمية عالمية وبمذاق وثقافة مصرية \" المناسبة لقدراتهم . . . التجريبية المتكاملة العلمية والتكنولوجية والرياضيات علاوة على أنشطة الإعداد للقراءة والكتابة، من إعداد أكاديمية مشروعات الهندسة الاجتماعية (مهاتك) - للتنمية التكنولوجية المبكرة - والتي تعمل على إتاحة البرامج العلمية والأنشطة التكنولوجية المبكرة للأطفال، أبناء الأسر والاجتماعية للمؤسس وشركائه. الجادة والواعية بأهمية هذه الأنشطة، وذلك من منطلق المسئولية العلمية والمهنية ",
  //     season : "صيفي",
  //     studentsCount: 600,
  //     hours: 420,
  //     activities: ["أنشطة العلوم التجريبية.", "الأنشطة الهندسية والتكنولوجية.","أنشطة المفاهيم الرياضية.","أنشطة تنمية مهارات ما قبل القراءة والكتابة.","الأنشطة الفنية.","الأنشطة القصصية والمسرحية."],
  //     goals: ["هدف 1", "هدف 2"],
  //     requirements: ["متطلب 1", "متطلب 2"],
  //     registrationSteps: ["خطوة 1", "خطوة 2"],
  //     extras: ["اضافة 1", "اضافة 2"],
  //     price: 600,
  //     visible: true,
  //     type: "ScientificClub",
  //     thumbnailPhoto: "/assets/thumbnailPhotos/dummyCourses/bebasata.png",
  //   },
  //   {
  //     index: 2,
  //     name: "البرنامج الثاني",
  //     eligibleAgeFrom: 10,
  //     eligibleAgeTo: 15,
  //     toWho: "الاطفال",
  //     programType: "StandaloneProgram",
  //     bref: "برنامج حر",
  //     definition: "برنامج حر",
  //     activities: ["أنشطة العلوم التجريبية.", "الأنشطة الهندسية والتكنولوجية.","أنشطة المفاهيم الرياضية.","أنشطة تنمية مهارات ما قبل القراءة والكتابة.","الأنشطة الفنية.","الأنشطة القصصية والمسرحية."],
  //     goals: ["هدف 1", "هدف 2"],
  //     requirements: ["متطلب 1", "متطلب 2"],
  //     registrationSteps: ["خطوة 1", "خطوة 2"],
  //     extras: ["اضافة 1", "اضافة 2"],
  //     price: 100,
  //     visible: true,
  //   },
  // ];
  const [programs, setPrograms] = useState([]);
  const [nwProgram, setNwProgram] = useState({
    name: "",
    eligibleAgeFrom: 0,
    eligibleAgeTo: 0,
    toWho: "",
    programType: "",
    bref: "",
    definition: "",
    activities: [],
    goals: [],
    requirements: [],
    registrationSteps: [],
    extras: [],
    gallery: [],
    season: "",
    price: 0,
    visible: true,
    thumbnailPhoto: "/assets/images/course/course-online-02.jpg",
  });
  const dispatch = useDispatch();
  let programsTemp = useSelector((rootState) => rootState.programs.programs);
  useEffect(() => {
    dispatch(fetchProgramsAsync()).then((res) => {
      if (res.payload) setPrograms(res.payload);
    });

    // dispatch(createProgramAsync({
    //   name: "نادي علوم ببساطة",
    //   eligibleAgeFrom: 3,
    //   eligibleAgeTo: 5,
    //   toWho: "للأطفال من (5-3 سنوات) مرحلة ما قبل الروضة",
    //   programType: "ScientificClub",
    //   bref: "للأطفال من (5-3 سنوات) مرحلة ما قبل الروضة",
    //   definition: "برنامج \" ببساطة \"هو أول برنامج وطني لتنمية التفكير العلمي والمنطقي لأطفال ما قبل مرحلة رياض الأطفال من عمر (3) إلى (5) سنوات من خلال تقديم مجموعة من الأنشطة \" برؤية علمية عالمية وبمذاق وثقافة مصرية \" المناسبة لقدراتهم . . . التجريبية المتكاملة العلمية والتكنولوجية والرياضيات علاوة على أنشطة الإعداد للقراءة والكتابة، من إعداد أكاديمية مشروعات الهندسة الاجتماعية (مهاتك) - للتنمية التكنولوجية المبكرة - والتي تعمل على إتاحة البرامج العلمية والأنشطة التكنولوجية المبكرة للأطفال، أبناء الأسر والاجتماعية للمؤسس وشركائه. الجادة والواعية بأهمية هذه الأنشطة، وذلك من منطلق المسئولية العلمية والمهنية ",
    //   activities: ["أنشطة العلوم التجريبية.", "الأنشطة الهندسية والتكنولوجية.","أنشطة المفاهيم الرياضية.","أنشطة تنمية مهارات ما قبل القراءة والكتابة.","الأنشطة الفنية.","الأنشطة القصصية والمسرحية."],
    //   goals: ["هدف 1", "هدف 2"],
    //   requirements: ["متطلب 1", "متطلب 2"],
    //   registrationSteps: ["خطوة 1", "خطوة 2"],
    //   extras: ["اضافة 1", "اضافة 2"],
    //   gallery: ["assets/images/blog/blog-grid-03.jpg", "assets/images/blog/blog-grid-03.jpg"],
    //   price: 600,
    //   visible: true,
    //   thumbnailPhoto: "/assets/thumbnailPhotos/dummyCourses/bebasata.png",
    // })).then((res) => {
    //   if(res.payload){
    //     console.log("Program Added", res.payload);
    //   }else{
    //     console.log("Program Not Added", res.payload);
    //   }
    // })
  }, [dispatch]);
  useEffect(() => {
    if (programsTemp) setPrograms(programsTemp);
  }, []);

  function addProgram() {
    console.log("Add Program");
    setPrograms([...programs, nwProgram]);
  }
  function updateProgram(program, index) {
    console.log("Update Program");
    setPrograms([
      ...programs.slice(0, index),
      program,
      ...programs.slice(index + 1),
    ]);
  }
  function deleteProgram(index) {
    console.log("Delete Program");
    setPrograms([...programs.slice(0, index), ...programs.slice(index + 1)]);
  }
  return (
    <>
      <div class="section-title text-center mb--25">
        <h2 class="title">البرامج</h2>
      </div>
      <Accordion
        defaultActiveKey="-1"
        className="col-12 mb--20 rbt-accordion-style rbt-accordion-04 accordion"
      >
        <Accordion.Item eventKey="0" className="accordion-item card">
          <Accordion.Header className="accordion-button accordion-header card-header">
            برنامج جديد
          </Accordion.Header>
          <Accordion.Body>
            <ProgramForm
              program={nwProgram}
              updateProgram={(t) => setNwProgram(t)}
              addProgram={() => addProgram()}
              isNew={true}
              index={-1}
              dispatch={dispatch}
              toggleSuccess={toggleSuccess}
              toggleError={toggleError}
            />
          </Accordion.Body>
        </Accordion.Item>
        <>
          {programs.map((program, index) => (
            <Accordion.Item
              eventKey={index + 1}
              className="accordion-item card"
              key={index}
            >
              <Accordion.Header className="accordion-button accordion-header card-header">
                {program.name}
              </Accordion.Header>
              <Accordion.Body>
                <ProgramForm
                  program={program}
                  updateProgram={(t) => updateProgram(t, index)}
                  deleteProgram={() => deleteProgram(index)}
                  isNew={false}
                  index={index}
                  dispatch={dispatch}
                  toggleSuccess={toggleSuccess}
                  toggleError={toggleError}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </>
      </Accordion>
    </>
  );
}
