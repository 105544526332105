import React from "react";

export default function Copyright() {
  return (
    <div className="copyright-area copyright-style-1 ptb--20">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <p className="rbt-link-hover text-center text-lg-end">
             جميع الحقوق محفوظة © 2024 أكاديمية مهاتك  
            </p>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <p className="rbt-link-hover text-center text-lg-start">
             Developed by{" "}
              <a href="https://www.solvytix.com" target="_blank" >
                Solvytix
              </a>
            </p>
          </div>

          {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
              <li>
                <a href="#">Terms of service</a>
              </li>
              <li>
                <a href="privacy-policy.html">Privacy policy</a>
              </li>
              <li>
                <a href="subscription.html">Subscription</a>
              </li>
              <li>
                <a href="login.html">Login & Register</a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}
