import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBlogsAsync } from "../features/blogs/blogsSlice";
import { Link } from "react-router-dom";
export default function BlogDetailsPage() {
  const { id } = useParams();
  let blogs = useSelector((rootState) => rootState.blogs.blogs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBlogsAsync(id));
  }, [dispatch, id]);
  const blog = blogs.find((blog) => blog.id === Number(id));
  if (!blog) return <div>Loading...</div>;
  let relatedBlogsids = [12, 13];
  let relatedBlogs = blogs.filter(
    (b) => b.id === relatedBlogsids[0] || b.id === relatedBlogsids[1]
  );
  let date = new Date(blog.publishDate);
  return (
    <div>
      <div class="rbt-overlay-page-wrapper">
        <div class="breadcrumb-image-container breadcrumb-style-max-width">
          <div class="breadcrumb-image-wrapper">
            <img
              src="/assets/images/bg/bg-image-10.jpg"
              alt="Education Images"
            />
          </div>
          <div class="breadcrumb-content-top text-center">
            <ul class="meta-list justify-content-center mb--10">
              {/* <li class="list-item">
                <div class="author-thumbnail">
                  <img
                    src="/assets/images/testimonial/client-06.png"
                    alt="blog-image"
                  />
                </div>
                 <div class="author-info">
                            <a href="#"><strong>Jone Song</strong></a> in <a href="#"><strong>Design</strong></a>
                        </div> 
              </li> */}
              <li class="list-item">
                <span>
                  {" "}
                  {date.toDateString()} {date.toLocaleTimeString()}
                </span>
                <i class="feather-clock"> </i>
              </li>
            </ul>
            <h1 class="title">{blog.title}</h1>
            {/* <p>Ten Advices That You Must Listen Before Studying Education.</p> */}
          </div>
        </div>

        <div class="rbt-blog-details-area rbt-section-gapBottom breadcrumb-style-max-width">
          <div class="blog-content-wrapper rbt-article-content-wrapper">
            <div class="content">
              <div class="post-thumbnail mb--30 position-relative wp-block-image alignwide">
                <figure>
                  <img src={blog.titlePhoto} alt="Blog Images" />
                  <figcaption>
                    {/* Business and core management app are for enterprise. */}
                  </figcaption>
                </figure>
              </div>
              <p>{blog.content}</p>
              <div class="tagcloud">
                {blog.tags.map((tag) => (
                    <a href="#">{tag}</a>
                ))}
            </div>
              {/* <div class="social-share-block">
                        <div class="post-like">
                            <a href="#"><i class="feather feather-thumbs-up"></i><span>2.2k Like</span></a>
                        </div>
                        <ul class="social-icon social-default transparent-with-border">
                            <li><a href="https://www.facebook.com/">
                                    <i class="feather-facebook"></i>
                                </a>
                            </li>
                            <li><a href="https://www.twitter.com">
                                    <i class="feather-twitter"></i>
                                </a>
                            </li>
                            <li><a href="https://www.instagram.com/">
                                    <i class="feather-instagram"></i>
                                </a>
                            </li>
                            <li><a href="https://www.linkdin.com/">
                                    <i class="feather-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div> */}

              {/* <div class="about-author">
                        <div class="media">
                            <div class="thumbnail">
                                <a href="#">
                                    <img src="/assets/images/testimonial/testimonial-4.jpg" alt="Author Images"/>
                                </a>
                            </div>
                            <div class="media-body">
                                <div class="author-info">
                                    <h5 class="title">
                                        <a class="hover-flip-item-wrapper" href="#">
                                            Farjana Bawnia
                                        </a>
                                    </h5>
                                    <span class="b3 subtitle">Sr. UX Designer</span>
                                </div>
                                <div class="content">
                                    <p class="description">At 29 years old, my favorite compliment is being
                                        told that I look like my mom. Seeing myself in her image, like this
                                        daughter up top.</p>
                                    <ul class="social-icon social-default icon-naked justify-content-start">
                                        <li><a href="https://www.facebook.com/">
                                                <i class="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li><a href="https://www.twitter.com">
                                                <i class="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li><a href="https://www.instagram.com/">
                                                <i class="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a href="https://www.linkdin.com/">
                                                <i class="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div> */}
            </div>
            <div class="related-post pt--60">
              <div class="section-title text-end mb--40">
                {/* <span class="subtitle bg-primary-opacity">Related Post</span> */}
                <h4 class="title">مدونات ذات صلة</h4>
              </div>
              {relatedBlogs.map((blog) => (
                <div class="rbt-card card-list variation-02 rbt-hover mt--30">
                  <div class="rbt-card-img">
                    <Link
                      to={`/blogs/${blog.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img src={blog.titlePhoto} alt="Card image" />{" "}
                    </Link>
                  </div>
                  <div class="rbt-card-body">
                    <h5 class="rbt-card-title">
                      <Link
                        to={`/blogs/${blog.id}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {blog.title}
                      </Link>
                    </h5>
                    <div class="rbt-card-bottom">
                      <Link
                        class="transparent-button"
                        to={`/blogs/${blog.id}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        اقرأ المزيد
                        {/* left arrow */}
                        <i
                          style={{ marginRight: "0.5rem" }}
                          className="feather-arrow-left"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
