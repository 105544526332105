import React from "react";
import ProgramCard from "../components/ProgramCard";

export default function ProgramSection() {
  return (
    <div class="rbt-categories-area bg-color-light rbt-section-gap">
      <div class="container">
        <div class="row g-5">
          <ProgramCard
            img="assets/images/Bebasata5.png"
            title="نوادي العلوم"
            description="البرامج التي تستمر برامجها لعدد من السنوات والتي تتسلسل وتتدرج برامجها من حيث المستوى والمحتوى والعمق حسب الفئة العمرية."
          />
          <ProgramCard
            img="assets/images/Arduino.png"
            title="برامج الحرة"
            description="البرامج التي ليس من الضروري عقدها كل عام وكذلك ليس لها مواعيد محددة وثابتة سنوية، حيث أنها تتغير حسب جدول برامج الأكاديمية."
          />
        </div>
      </div>
    </div>
  );
}
