import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import ArrayInput from "../ArrayInputs/ArrayInput";
import { useSelector } from "react-redux";
import {
  fetchJobsAsync,
  updateJobAsync,
  deleteJobAsync,
  createJobAsync,
} from "../../features/jobs/jobsSlice";
export default function JobForm({
  job,
  updateJob,
  addJob,
  deleteJob,
  isNew,
  dispatch,
  index,
  toggleError,
  toggleSuccess,
}) {
  const [operation, setOperation] = useState(false);
  let status = useSelector((state) => state.jobs.status);
  useEffect(() => {
    if (operation) {
      if (status === "succeeded") {
        toggleError();
        dispatch(fetchJobsAsync());
        setOperation(false);
      } else if (status === "failed") {
        toggleSuccess();
        alert("فشلت العملية");
        setOperation(false);
      }
    }
  }, [status, operation, dispatch]);

  function handleJobUpdate() {
    // e.preventDefault();
    setOperation(true);
    dispatch(updateJobAsync(job));
  }
  function handleJobDelete() {
    // e.preventDefault();
    if (!window.confirm("هل تريد مسح الوظيفة؟")) return;
    setOperation(true);
    dispatch(deleteJobAsync(job.id));
  }
  function handleJobAdd() {
    // e.preventDefault();
    setOperation(true);
    dispatch(createJobAsync(job));
    updateJob({
      name: "",
      description: "",
      requirements: [],
      responsibilities: [],
      gender: 0,
      visible: true,
    });
  }
  return (
    <form class="row g-5 checkout-form">
      <div class="col-lg-12">
        <div class="checkout-content-wrapper">
          <div id="billing-form">
            <div class="row">
              <div class="col-md-6 col-12 mb--20">
                <label>اسم الوظيفة*</label>
                <input
                  type="text"
                  placeholder="اسم الوظيفة"
                  value={job.name}
                  onChange={(e) => updateJob({ ...job, name: e.target.value })}
                />
              </div>
              <div class="col-md-6 col-12 mb--20">
                <label>الجنس*</label>
                <select
                  value={job.gender}
                  onChange={(e) =>
                    updateJob({ ...job, gender: parseInt(e.target.value) })
                  }
                >
                  <option value="0">ذكر</option>
                  <option value="1">انثى</option>
                </select>
              </div>
              <div class="col-md-12 col-12 mb--20">
                <label>الوصف*</label>
                <textarea
                  rows={4}
                  type="text"
                  placeholder="الوصف"
                  value={job.description}
                  onChange={(e) =>
                    updateJob({ ...job, description: e.target.value })
                  }
                />
              </div>
              <Accordion
                defaultActiveKey="-1"
                className="col-12 mb--20 rbt-accordion-style rbt-accordion-02 accordion"
              >
                <Accordion.Item eventKey="0" className="accordion-item card">
                  <Accordion.Header className="accordion-button accordion-header card-header">
                    المتطلبات*
                  </Accordion.Header>
                  <Accordion.Body>
                    <ArrayInput
                      title={"المتطلبات"}
                      list={job.requirements}
                      setList={(list) =>
                        updateJob({ ...job, requirements: list })
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="accordion-item card">
                  <Accordion.Header className="accordion-button accordion-header card-header">
                    المسؤوليات*
                  </Accordion.Header>
                  <Accordion.Body>
                    <ArrayInput
                      title={"المسؤوليات"}
                      list={job.responsibilities}
                      setList={(list) =>
                        updateJob({ ...job, responsibilities: list })
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div class="col-12 mb--20" style={{ float: "right" }}>
                <div class="check-box">
                  <input
                    type="checkbox"
                    id={`visible${index}`}
                    checked={job.visible}
                    onChange={(e) =>
                      updateJob({ ...job, visible: e.target.checked })
                    }
                  />
                  <label for={`visible${index}`}>ظاهر</label>
                </div>
              </div>
              <div class="rbt-button-group">
                {isNew ? (
                  <button class="rbt-btn" type="button" onClick={handleJobAdd}>
                    اضافة
                  </button>
                ) : (
                  <>
                    <button
                      class="rbt-btn"
                      type="button"
                      onClick={handleJobUpdate}
                    >
                      تعديل
                    </button>
                    <button
                      class="rbt-btn btn-secondary"
                      type="button"
                      onClick={handleJobDelete}
                    >
                      مسح
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
