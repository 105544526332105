import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumb({ label }) {
  return (
    <div class="rbt-breadcrumb-default ptb--200 ptb_md--100 pt_sm--100 pb_sm--50 bg-gradient-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner text-center">
              <h2 class="title">{label}</h2>
              <ul class="page-list">
                <li class="rbt-breadcrumb-item">
                  <Link to="/" onClick={()=>window.scrollTo(0, 0)}>الرئيسية</Link>
                </li>
                <li>
                  <div class="icon-left">
                    <i class="feather-chevron-left"></i>
                  </div>
                </li>
                <li class="rbt-breadcrumb-item active">{label}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
