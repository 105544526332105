import React, { useEffect, useState } from "react";
import {
  fetchFaqsAsync,
  updateFaqAsync,
  deleteFaqAsync,
  createFaqAsync,
} from "../../features/faq/faqSlice";
import { useSelector } from "react-redux";
import { set } from "react-hook-form";

export default function FaqForm({
  faq,
  updateFaq,
  deleteFaq,
  addFaq,
  isNew,
  dispatch,
  index,
  toggleError,
  toggleSuccess,
}) {
  let status = useSelector((state) => state.faq.status);
  const [operation, setOperation] = useState(false);
  let showSuccess = false,
    showError = false;
  useEffect(() => {
    if (operation) {
      if (status === "succeeded") {
        dispatch(fetchFaqsAsync());
        setOperation(false);
        toggleSuccess();
      } else if (status === "failed") {
        setOperation(false);
        toggleError();
      }
    }
  }, [status, operation, dispatch]);
  function handleFaqUpdate() {
    dispatch(updateFaqAsync(faq));
    setOperation(true);
  }
  function handleFaqDelete() {
    if (!window.confirm("هل تريد مسح السؤال؟")) return;
    dispatch(deleteFaqAsync(faq.id));
    setOperation(true);
  }
  function handleFaqAdd() {
    dispatch(createFaqAsync(faq));
    setOperation(true);
    updateFaq({
      question: "",
      answer: "",
      visible: true,
      id:0
    });
  }
  
  return (
    <form class="row g-5 checkout-form">
      <div class="col-lg-12">
        <div class="checkout-content-wrapper">
          <div id="billing-form">
            <div class="row">
              <div class="col-md-12 col-12 mb--20">
                <label>السؤال*</label>
                <textarea
                  type="text"
                  rows={4}
                  placeholder="السؤال"
                  value={faq.question}
                  onChange={(e) => {
                    updateFaq({ ...faq, question: e.target.value });
                  }}
                  required
                />
              </div>
              <div class="col-md-12 col-12 mb--20">
                <label>الاجابة*</label>
                <textarea
                  rows={4}
                  type="text"
                  placeholder="الاجابة"
                  value={faq.answer}
                  onChange={(e) => {
                    updateFaq({ ...faq, answer: e.target.value });
                  }}
                  required
                />
              </div>
              <div class="col-12 mb--20" style={{ float: "right" }}>
                <div class="check-box">
                  <input
                    type="checkbox"
                    id={`visible${index}`}
                    checked={faq.visible}
                    onChange={(e) => {
                      updateFaq({ ...faq, visible: e.target.checked });
                    }}
                  />
                  <label for={`visible${index}`}>ظاهر</label>
                </div>
              </div>
              <div class="rbt-button-group">
                {isNew ? (
                  <button class="rbt-btn" type="button" onClick={handleFaqAdd}>
                    اضافة
                  </button>
                ) : (
                  <>
                    <button
                      class="rbt-btn"
                      type="button"
                      onClick={handleFaqUpdate}
                    >
                      تعديل
                    </button>
                    <button
                      class="rbt-btn btn-secondary"
                      type="button"
                      onClick={handleFaqDelete}
                    >
                      مسح
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
