import ArrayInput from "../ArrayInputs/ArrayInput";
import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import ImagePicker from "../ArrayInputs/ImagePicker";
import ArrayImageInput from "../ArrayInputs/ArrayImageInput";
import { useSelector } from "react-redux";
import {
  fetchProgramsAsync,
  updateProgramAsync,
  deleteProgramAsync,
  createProgramAsync,
} from "../../features/programs/programsSlice";

export default function ProgramForm({
  program,
  updateProgram,
  addProgram,
  deleteProgram,
  isNew,
  dispatch,
  index,
  toggleError,
  toggleSuccess,
}) {
  let status = useSelector((state) => state.programs.status);

  const [operation, setOperation] = useState(false);
  useEffect(() => {
    if (operation) {
      if (status === "succeeded") {
        toggleSuccess();
        dispatch(fetchProgramsAsync());
        setOperation(false);
        updateProgram({
          name: "",
          eligibleAgeFrom: 0,
          eligibleAgeTo: 0,
          toWho: "",
          programType: "",
          bref: "",
          definition: "",
          activities: [],
          goals: [],
          requirements: [],
          registrationSteps: [],
          extras: [],
          gallery: [],
          season: "",
          price: 0,
          visible: true,
          thumbnailPhoto: "/assets/images/course/course-online-02.jpg",
        });
      } else if (status === "failed") {
        toggleError();
        setOperation(false);
      }
    }
  }, [status, operation, dispatch]);

  function handleProgramUpdate() {
    // e.preventDefault();
    setOperation(true);
    dispatch(updateProgramAsync(program));
  }
  function handleProgramDelete(e) {
    // e.preventDefault();
    if (!window.confirm("هل تريد مسح البرنامج؟")) return;
    setOperation(true);
    dispatch(deleteProgramAsync(program.id));
  }
  function handleProgramAdd(e) {
    // e.preventDefault();
    setOperation(true);
    dispatch(createProgramAsync(program));
    
  }
  return (
    <form class="row g-5 checkout-form">
      <div class="col-lg-12">
        <div class="checkout-content-wrapper">
          <div id="billing-form">
            <div class="row">
              <ImagePicker
                title="صورة البرنامج"
                image={program.thumbnailPhoto}
                ImageURL={program.thumbnailPhoto}
                setImageURL={(image) =>
                  updateProgram({ ...program, thumbnailPhoto: image })
                }
                setImage={(image) =>
                  updateProgram({ ...program, thumbnailPhoto: image })
                }
                index={index}
                id="thumbnailPhoto"
              />

              <div class="col-md-6 col-12 mb--20">
                <label>أسم البرنامج*</label>
                <input
                  type="text"
                  placeholder="أسم البرنامج"
                  value={program.name}
                  onChange={(e) =>
                    updateProgram({ ...program, name: e.target.value })
                  }
                />
              </div>

              <div class="col-md-3 col-12 mb--20 ">
                <label>نوع البرنامج*</label>
                <div class="rbt-modern-select bg-transparent height-45 text-end">
                  <select
                    class="w-100 text-end"
                    style={{ textAlign: "left" }}
                    value={program.programType}
                    onChange={(e) =>
                      updateProgram({ ...program, programType: e.target.value })
                    }
                  >
                    <option value="ScientificClub">نادي علوم</option>
                    <option value="StandaloneProgram">برنامج حر</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-12 mb--20 ">
                <label>موسم*</label>
                <div class="rbt-modern-select bg-transparent height-45 text-end">
                  <select
                    class="w-100 text-end"
                    style={{ textAlign: "left" }}
                    value={program.season}
                    onChange={(e) =>
                      updateProgram({ ...program, season: e.target.value })
                    }
                  >
                    <option value="Summer"> صيفي</option>
                    <option value="Winter"> شتوي</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-12 mb--20">
                <label>من عمر*</label>
                <input
                  type="text"
                  placeholder="من عمر"
                  value={program.eligibleAgeFrom}
                  onChange={(e) =>
                    updateProgram({
                      ...program,
                      eligibleAgeFrom: parseInt(e.target.value),
                    })
                  }
                />
              </div>

              <div class="col-md-3 col-12 mb--20">
                <label>الى عمر*</label>
                <input
                  type="text"
                  placeholder="الى عمر"
                  value={program.eligibleAgeTo}
                  onChange={(e) =>
                    updateProgram({
                      ...program,
                      eligibleAgeTo: parseInt(e.target.value),
                    })
                  }
                />
              </div>

              <div class="col-md-6 col-12 mb--20">
                <label>من المستهدف*</label>
                <input
                  type="text"
                  placeholder="من المستهدف"
                  value={program.toWho}
                  onChange={(e) =>
                    updateProgram({ ...program, toWho: e.target.value })
                  }
                />
              </div>
              <div class="col-md-3 col-12 mb--20">
                <label>عدد الطلاب*</label>
                <input
                  type="text"
                  placeholder="عدد الطلاب"
                  value={program.studentsCount}
                  onChange={(e) =>
                    updateProgram({
                      ...program,
                      studentsCount: parseInt(e.target.value),
                    })
                  }
                />
              </div>

              <div class="col-md-3 col-12 mb--20">
                <label>عدد الساعات*</label>
                <input
                  type="text"
                  placeholder="عدد الساعات"
                  value={program.hours}
                  onChange={(e) =>
                    updateProgram({
                      ...program,
                      hours: parseInt(e.target.value),
                    })
                  }
                />
              </div>
              <div class="col-md-3 col-12 mb--20">
                <label>يبدا من سعر*</label>
                <input
                  type="text"
                  placeholder="يبدا من سعر"
                  value={program.price}
                  onChange={(e) =>
                    updateProgram({
                      ...program,
                      price: parseInt(e.target.value),
                    })
                  }
                />
              </div>

              <div class="col-md-12 col-12 mb--20">
                <label> ملخص البرنامج*</label>
                <input
                  type="text"
                  placeholder="ملخص البرنامج"
                  value={program.bref}
                  onChange={(e) =>
                    updateProgram({ ...program, bref: e.target.value })
                  }
                />
              </div>

              <div class="col-12 mb--20">
                <label>تعريف البرنامج*</label>
                <textarea
                  type="text"
                  placeholder="تعريف البرنامج"
                  rows={4}
                  value={program.definition}
                  onChange={(e) =>
                    updateProgram({ ...program, definition: e.target.value })
                  }
                ></textarea>
              </div>

              <Accordion
                defaultActiveKey="-1"
                className="col-12 mb--20 rbt-accordion-style rbt-accordion-02 accordion"
              >
                <div className="accordion">
                  <Accordion.Item eventKey="0" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      الانشطة
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayInput
                        title="الانشطة"
                        list={program.activities}
                        setList={(list) =>
                          updateProgram({ ...program, activities: list })
                        }
                        class="col-12 mb--20"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      الاهداف
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayInput
                        title="الاهداف"
                        list={program.goals}
                        setList={(list) =>
                          updateProgram({ ...program, goals: list })
                        }
                        class="col-12 mb--20"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      المتطلبات
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayInput
                        title="المتطلبات"
                        list={program.requirements}
                        setList={(list) =>
                          updateProgram({ ...program, requirements: list })
                        }
                        class="col-12 mb--20"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      خطوات التسجيل
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayInput
                        title="خطوات التسجيل"
                        list={program.registrationSteps}
                        setList={(list) =>
                          updateProgram({ ...program, registrationSteps: list })
                        }
                        class="col-12 mb--20"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      اضافات
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayInput
                        title="اضافات"
                        list={program.extras}
                        setList={(list) =>
                          updateProgram({ ...program, extras: list })
                        }
                        class="col-12 mb--20"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5" className="accordion-item card">
                    <Accordion.Header className="accordion-button accordion-header card-header">
                      ألبوم الصور
                    </Accordion.Header>
                    <Accordion.Body>
                      <ArrayImageInput
                        title="ألبوم الصور"
                        list={program.gallery}
                        setList={(list) =>
                          updateProgram({ ...program, gallery: list })
                        }
                        class="col-12 mb--20"
                        id="gallery"
                        defaultImage={"/assets/images/course/course-01.jpg"}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </Accordion>
              <div class="col-12 mb--20" style={{ float: "right" }}>
                <div class="check-box">
                  <input
                    type="checkbox"
                    id={`visible${index}`}
                    checked={program.visible}
                    onChange={(e) =>
                      updateProgram({ ...program, visible: e.target.checked })
                    }
                  />
                  <label for={`visible${index}`}>ظاهر</label>
                </div>
              </div>
              <div class="rbt-button-group">
                {isNew ? (
                  <button
                    class="rbt-btn"
                    type="button"
                    onClick={handleProgramAdd}
                    disabled={operation}
                  >
                    {operation ? "..." : "اضافة"}
                  </button>
                ) : (
                  <>
                    <button
                      class="rbt-btn"
                      type="button"
                      onClick={handleProgramUpdate}
                      disabled={operation}
                    >
                      {operation ? "..." : "تعديل"}
                    </button>
                    <button
                      class="rbt-btn btn-secondary"
                      type="button"
                      onClick={handleProgramDelete}
                      disabled={operation}
                    >
                      {operation ? "..." : "مسح"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
