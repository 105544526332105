export default function ProgramCardInGrid({ program }) {
  return (
    <div class="course-grid-3">
      <div class="rbt-card variation-01 rbt-hover">
        <div class="rbt-card-img">
          <a href= {`/programs/${program.id}`}>
            <img
              src={program.thumbnailPhoto}
              alt="Card image"
            />
          </a>
        </div>
        <div class="rbt-card-body">
          {/* <div class="rbt-card-top">
            <div class="rbt-review">
              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <span class="rating-count"> (15 Reviews)</span>
            </div>
            <div class="rbt-bookmark-btn">
              <a class="rbt-round-btn" title="Bookmark" href="#">
                <i class="feather-bookmark"></i>
              </a>
            </div>
          </div> */}
          <h4 class="rbt-card-title">
            <a href= {`/programs/${program.id}`}>{program.name}</a>
          </h4>
          <ul class="rbt-meta">
            <li>
              {program.hours} ساعة<i class="feather-book"></i>
            </li>
            <li>
              اكثر من {program.students} طالب <i class="feather-users"></i>
            </li>
          </ul>
           <p class="rbt-card-text">{program.bref}</p>
          <div class="rbt-card-bottom">
            {/* <div class="rbt-price">
              <span class="current-price">$60</span>
              <span class="off-price">$120</span>
            </div> */}
            <a class="rbt-btn-link" href= {`/programs/${program.id}`}>
              لمعلومات اكثر <i class="feather-arrow-left"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
