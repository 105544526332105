import axios from "axios";

const baseURL = "https://api.mahatech.org/api"


// Create an Axios instance with custom configuration
const axiosInstance = axios.create({
  baseURL: baseURL, // Your backend API base URL
  timeout: 10000, // Request timeout
});

// Add an interceptor to handle CORS-related issues
axiosInstance.interceptors.response.use(
  (response) => {
    // Successful response
    return response.data;
  },
  (error) => {
    // Error handling for failed responses
    const errorMessage =
      error.response?.data?.message || "Failed to perform the request";
    return Promise.reject(new Error(errorMessage));
  }
);

// Export the configured Axios instance
export default axiosInstance;
