import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import JobForm from "../AdminForms/JobForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsAsync } from "../../features/jobs/jobsSlice"

// let jobsTemp = [
//   {
//     id: 1,
//     name: "وظيفة 1",
//     description: "وصف الوظيفة 1",
//     requirements: ["متطلب 1", "متطلب 2"],
//     responsibilities: ["مسؤولية 1", "مسؤولية 2"],
//     gender: 0,
//     visible: true,
//   },
//   {
//     id: 2,
//     postionName: "وظيفة 2",
//     jobDescription: "وصف الوظيفة 2",
//     requirements: ["متطلب 1", "متطلب 2"],
//     responsibilities: ["مسؤولية 1", "مسؤولية 2"],
//     gender: 1,
//     visible: true,
//   },
// ];
export default function JobList({toggleError, toggleSuccess}) {
  const [jobs, setJobs] = useState([]);
  const [nwJob, setNwJob] = useState({
    name: "",
    description: "",
    requirements: [],
    responsibilities: [],
    gender: 0,
    visible: true,
  });
  const dispatch = useDispatch();
  let jobsTemp = useSelector((rootState) => rootState.jobs.jobs);

  useEffect(() => {
    dispatch(fetchJobsAsync()).then((res) => {
      if (res.payload) setJobs(res.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    if (jobsTemp) setJobs(jobsTemp);
  }, []);

  function addJob() {
    console.log("Add Job");
    setJobs([...jobs, nwJob]);
  }

  function updateJob(job, index) {
    console.log("Update Job");
    setJobs([...jobs.slice(0, index), job, ...jobs.slice(index + 1)]);
  }

  function deleteJob(index) {
    console.log("Delete Job");
    setJobs([...jobs.slice(0, index), ...jobs.slice(index + 1)]);
  }

  return (
    <>
      <div class="section-title text-center mb--25">
        <h2 class="title">الوظائف</h2>
      </div>
      <Accordion
        defaultActiveKey="-1"
        className="col-12 mb--20 rbt-accordion-style rbt-accordion-04 accordion"
      >
        <Accordion.Item eventKey="0" className="accordion-item card">
          <Accordion.Header className="accordion-button accordion-header card-header">
            وظيفة جديدة
          </Accordion.Header>
          <Accordion.Body>
            <JobForm
              job={nwJob}
              updateJob={(t) => setNwJob(t)}
              addJob={() => addJob()}
              isNew={true}
              dispatch={dispatch}
              index={-1}
              toggleError={toggleError}
              toggleSuccess={toggleSuccess}
            />
          </Accordion.Body>
        </Accordion.Item>
        {jobs.map((job, index) => (
          <Accordion.Item
            eventKey={index + 1}
            className="accordion-item card"
            key={index}
          >
            <Accordion.Header className="accordion-button accordion-header card-header">
              {job.name}
            </Accordion.Header>
            <Accordion.Body>
              <JobForm
                job={job}
                updateJob={(t) => updateJob(t, index)}
                deleteJob={() => deleteJob(index)}
                isNew={false}
                dispatch={dispatch}
                index={index}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
