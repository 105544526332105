import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

function getFormData(params) {
  let formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value) {
      if(key === 'cv'){
        formData.append('cv', value[0]);
      }else
        formData.append(key, value);
  
    }
  }
  return formData;
}

export const createApplicant = async (applicantData) => {
  try {
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const response = await axios.post(
      `${BASE_URL}/Applicant`,
      getFormData(applicantData)
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.message || "Failed to create program registration"
    );
  }
};

export const fetchApplicants = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/Applicant`);
    return response;
  } catch (error) {
    throw new Error(
      error.response?.message || "Failed to fetch program registrations"
    );
  }
};
