import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="rbt-footer footer-style-1">
      <div className="footer-top">
        <div className="container">
          <div className="row row--15 mt_dec--30">
            <div className="offset-lg-1 col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
              <div className="footer-widget">
                <div className="logo">
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    <img src="/assets/images/logo/logo.png" alt="Edu-cause" />
                  </Link>
                </div>

                <p className="description mt--20">
                  تقدم أكاديمية مهاتك برامجها و أنشطتها العلمية في مجال "التنمية
                  العلمية و التكنولوجية المبكرة للأطفال والنشء" .
                </p>

                <div className="contact-btn mt--30">
                  <Link
                    className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
                    to="/contact"
                    // scroll to top with smooth behavior
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div className="icon-reverse-wrapper">
                      <span className="btn-text">اتصل بنا</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-left"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-left"></i>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
              <div className="footer-widget">
                <h5 className="ft-title">برامج الأكاديمية</h5>
                <ul className="ft-link">
                  <li>
                    <Link to="/programs" onClick={()=>window.scrollTo(0, 0)}> نوادي العلوم</Link>
                  </li>
                  <li>
                  <Link to="/programs" onClick={()=>window.scrollTo(0, 0)}> البرامج الحرة</Link>
                  </li>
                
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
              <div className="footer-widget">
                <h5 className="ft-title">الروابط</h5>
                <ul className="ft-link">
                  <li>
                    <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                      عن الأكاديمية
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs" onClick={() => window.scrollTo(0, 0)}>
                      المدونة
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" onClick={() => window.scrollTo(0, 0)}>
                      الأسئلة الشائعة
                    </Link>
                  <li>
                    <Link to="/careers" onClick={() => window.scrollTo(0, 0)}>
                      الوظائف
                    </Link>
                  </li>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
              <div className="footer-widget">
                <h5 className="ft-title">تواصل معنا</h5>
                <ul className="ft-link">
                  <li>
                    <span>الموبايل: </span>{" "}
                    <a href="https://wa.me/+201019999357" target="_blank">
                      01019999357
                    </a>{" "}
                    -{" "}
                    <a href="https://wa.me/+201123655002" target="_blank">
                      01123655002
                    </a>
                  </li>
                  <li>
                    <span>الإيميل: </span>
                    <a href="mailto:center@mahatech.org">center@mahatech.org</a>
                  </li>
                  <li>
                    <span>العنوان: </span> مبنى أكاديمية مهاتك - شارع (1) -
                    زهراء حلوان - القاهرة - بجوار محطة مترو وادي حوف.
                  </li>
                </ul>
                <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                  <li>
                    <a
                      href="https://www.facebook.com/mahatech.center"
                      target="_blank"
                    >
                      <i className="feather-facebook"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/mahatech_academy/"
                      target="_blank"
                    >
                      <i className="feather-instagram"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://www.linkdin.com/">
                      <i className="feather-linkedin"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
