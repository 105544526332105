import React, { useEffect } from "react";
import BlogCard from "../components/BlogCard";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlogsAsync,
  createBlogAsync,
  deleteBlogAsync,
  updateBlogAsync,
} from "../features/blogs/blogsSlice";

export default function BlogsPage() {
  const blogs = useSelector((rootState) => rootState.blogs.blogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlogsAsync());

    // dispatch(
    //   createBlogAsync({
    //     title: "Idsadsadsa?",
    //     publishDate: "2024-05-04T10:34:55.448Z",
    //     tags: ["tag1", "tag2"],
    //     titlePhoto: "assets/images/blog/kindergarten-01.jpg",
    //     content: "It is a long established fact that a reader.",
    //     visible: true,
    //   })
    // );

    // dispatch(deleteBlogAsync(8));

    // dispatch(
    //   updateBlogAsync({
    //     id: 2,
    //     title:
    //       "(update test last1) Is Kindergarten The Most Trending Thing Now?",
    //     publishDate: "2024-05-04T10:34:55.448Z",
    //     tags: ["tag1", "tag2"],
    //     titlePhoto: "assets/images/blog/kindergarten-01.jpg",
    //     content: "It is a long established fact that a reader.",
    //     visible: true,
    //   })
    // );
  }, [dispatch]);

  return (
    <div>
      <div className="rbt-page-banner-wrapper">
        <div className="rbt-banner-image"></div>
        <div className="rbt-banner-content">
          <div style={{ marginTop: "7rem" }} className="rbt-banner-content-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="page-list">
                    <li className="rbt-breadcrumb-item">
                      <Link to="/">الرئيسية</Link>
                    </li>
                    <li>
                      <div className="icon-left">
                        <i className="feather-chevron-left"></i>
                      </div>
                    </li>
                    <li className="rbt-breadcrumb-item active">المدونة</li>
                  </ul>

                  <div className=" title-wrapper">
                    <h1 className="title mb--0">المدونة</h1>
                    <a href="#" className="rbt-badge-2 mr--20">
                      {blogs.length} مدونة   <div className="image">📖</div>
                    </a>
                  </div>

                  <p className="description">
                    تعرف على آخر الأخبار والمقالات والنصائح الخاصة بنا
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-blog-area rbt-section-overlayping-top rbt-section-gapBottom">
        <div className="container">
          <div className="row g-5 mt--15">
            {blogs.map((blog) => (
              <BlogCard blog={blog} key={blog.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
