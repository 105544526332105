// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login, register, logout } from "../../api/authApi";

export const loginAsync = createAsyncThunk("auth/login", async (userData) => {
  const response = await login(userData);
  localStorage.setItem("username", userData.userName);
  localStorage.setItem("token", response.token);
  return response;
});

export const registerAsync = createAsyncThunk(
  "auth/register",
  async (userData) => {
    const response = await register(userData);
    localStorage.setItem("username", userData.userName);
    localStorage.setItem("token", response.token);
    return response;
  }
);

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  await logout();
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload.token;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload.token;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(logoutAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.status = "succeeded";
        state.token = null;
      });
  },
});

export default authSlice.reducer;
