import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

export const createProgramRegistration = async (programRegistrationData) => {
  try {
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(
      `${BASE_URL}/ProgramRegisteredParticipant`,
      programRegistrationData
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.message || "Failed to create program registration"
    );
  }
};

export const fetchProgramRegistrations = async () => {
  try {

    const response = await axios.get(
      `${BASE_URL}/ProgramRegisteredParticipant`
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.message || "Failed to fetch program registrations"
    );
  }
};
