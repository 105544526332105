import { Link } from "react-router-dom";
export default function ProgramCard({ img, title, description }) {
  return (
    <div class="col-md-6 col-sm-6 col-12">
      <div class="rbt-cat-box rbt-cat-box-1 variation-3 text-center">
        <div class="inner">
          <div class="">
            <Link to="/programs/" onClick={() => window.scrollTo(0, 0)}>
              <img src={img} alt={title} />
              {/* <div class="read-more-btn">
                <span class="rbt-btn btn-sm btn-white radius-round">
                  15 Courses
                </span>
              </div> */}
            </Link>
          </div>
          <div class="content">
            <h5 class="title">
              <Link to="/programs/" onClick={() => window.scrollTo(0, 0)}>
                {title}
              </Link>
            </h5>
            <p class="description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
