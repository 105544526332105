import React from "react";
import FAQAccordionCard from "../components/FAQAccordionCard";
import { fetchFaqsAsync } from "../features/faq/faqSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// let faqs = [
//   {
//     question: "What is Histudy ? How does it work?",
//     answer:
//       "You can run Histudy easily. Any School, University, College can be use this histudy education template for their educational purpose. A university can be success you. Run their online leaning management system by histudy education template any where and time.",
//   },
//   {
//     question: "How can I get the customer support?",
//     answer:
//     "After purchasing the product need you any support you can be share with us with sending mail to"
//   },
//   {
//     question: "Can I get update regularly and For how long do I get updates?",
//     answer:
//       "Yes, We will get update the Histudy. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.",
//   },
// ];
export default function FaqSection() {
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.faq.faq);
  useEffect(() => {
    dispatch(fetchFaqsAsync());
  }, [dispatch]);
  if (!faqs) return null;
  let latestFaqs = faqs.slice(0, 3);
  return (
    <div class="rbt-categories-area bg-color-light rbt-section-gap ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center">
              <span class="subtitle bg-primary-opacity">أخبار تهمك</span>
              <h2 class="title">أهم الأسئلة الشائعة</h2>
              <p class="description has-medium-font-size mt--20">
                يسعدنا أن نجيب على أكثر الأسئلة والاستفسارات شيوعاً
              </p>
            </div>
          </div>
        </div>
        <div class="row g-5 mt--30">
          <div class="col-lg-12">
            <div class="rbt-accordion-style rbt-accordion-01  accordion">
              <div class="accordion" id="accordionExamplea1">
                {latestFaqs.map((faq, index) => (
                  <FAQAccordionCard faq={faq} index={index} key={index} />
                ))}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="load-more-btn mt--60 text-center">
                <Link
                  class="rbt-btn rbt-marquee-btn"
                  to="/faq"
                  onClick={() => window.scroll(0, 0)}
                >
                  <span data-text="View All Post"> تصفح المزيد </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
