import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchFaqs,
  fetchFaq,
  createFaq,
  updateFaq,
  deleteFaq,
} from "../../api/faqApi";

export const fetchFaqsAsync = createAsyncThunk("faq/fetchFaqs", async () => {
  return await fetchFaqs();
});
export const fetchFaqAsync = createAsyncThunk(
  "faq/fetchFaq",
  async (faqId) => {
    return await fetchFaq(faqId);
  }
);
export const createFaqAsync = createAsyncThunk(
  "faq/createFaq",
  async (faqData) => {
    return await createFaq(faqData);
  }
);
export const updateFaqAsync = createAsyncThunk(
  "faq/updateFaq",
  async (faqData) => {
    return await updateFaq(faqData.id, faqData);
  }
);
export const deleteFaqAsync = createAsyncThunk(
  "faq/deleteFaq",
  async (faqId) => {
    return await deleteFaq(faqId);
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faq: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch FAQs
      .addCase(fetchFaqsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFaqsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faq = action.payload;
      })
      .addCase(fetchFaqsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch FAQ
      .addCase(fetchFaqAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFaqAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faq.push(action.payload);
      })
      .addCase(fetchFaqAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create FAQ
      .addCase(createFaqAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFaqAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faq.push(action.payload);
      })
      .addCase(createFaqAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update FAQ
      .addCase(updateFaqAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFaqAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faq.push(action.payload);
      })
      .addCase(updateFaqAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete FAQ
      .addCase(deleteFaqAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFaqAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faq.push(action.payload);
      })
      .addCase(deleteFaqAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default faqSlice.reducer;
