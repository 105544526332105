import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchProgramsAsync } from "../features/programs/programsSlice";
import { createProgramRegistrationAsync } from "../features/programRegistration/programRegistrationSlice";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";

function ProgramRegistrationForm() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  function toggleSuccess() {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  }
  function toggleError() {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }

  const [operation, setOperation] = useState(false);
  const [programs, setPrograms] = useState([]);
  const dispatch = useDispatch();
  let status = useSelector((state) => state.programs.status);
  let temp = useSelector((rootState) => rootState.programs.programs);

  useEffect(() => {
    dispatch(fetchProgramsAsync()).then((res) => {
      if (res.payload) {
        setPrograms(res.payload);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (temp) setPrograms(temp);
  }, [temp]);

  useEffect(() => {}, [status, operation, dispatch]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data,e) => {
    setOperation(true);
    dispatch(createProgramRegistrationAsync(data)).then((res) => {
      if (res.payload) {
        toggleSuccess();
        e.target.reset();
      } else {
        toggleError();
      }
      setOperation(false);
    });
  };

  return (
    <form className="max-width-auto" onSubmit={handleSubmit(onSubmit)}>
      {showSuccess && <Alert variant="success">تم التسجيل بنجاح</Alert>} 
      {showError && <Alert variant="danger">فشل التسجيل</Alert>}
      {errors.parentName && (
        <span style={{ color: "red" }}>اسم ولي الأمر مطلوب</span>
      )}
      <div className="form-group">
        <input
          name="parentName"
          type="text"
          {...register("parentName", { required: true })}
        />
        <label>اسم ولي الأمر *</label>
        <span className="focus-border"></span>
      </div>

      {errors.name && <span style={{ color: "red" }}>اسم الطالب مطلوب</span>}
      <div className="form-group">
        <input
          name="name"
          type="text"
          {...register("name", { required: true })}
        />
        <label>اسم الطالب *</label>
        <span className="focus-border"></span>
      </div>
      {errors.birthDate && (
        <span style={{ color: "red" }}>تاريخ الميلاد مطلوب</span>
      )}
      <br />
      <label>تاريخ الميلاد *</label>
      <div className="form-group">
        <input
          name="birthDate"
          type="date"
          {...register("birthDate", { required: true })}
        />
        <span className="focus-border"></span>
      </div>

      {errors.parentEmail && (
        <span style={{ color: "red" }}>البريد الإلكتروني مطلوب</span>
      )}
      <div className="form-group">
        <input
          name="parentEmail"
          type="email"
          {...register("parentEmail", { required: true })}
        />
        <label>البريد الإلكتروني *</label>
        <span className="focus-border"></span>
      </div>

      {errors.phoneNumber && (
        <span style={{ color: "red" }}>رقم التلفون مطلوب</span>
      )}
      <div className="form-group">
        <input
          name="phoneNumber"
          type="tel"
          {...register("phoneNumber", { required: true })}
        />
        <label>رقم التلفون *</label>
        <span className="focus-border"></span>
      </div>

      <div className="form-group">
        <textarea name="comment" rows="4" {...register("comment")} />
        <label>تعليق (اختياري)</label>
        <span className="focus-border"></span>
      </div>

      {errors.programId && <span style={{ color: "red" }}>البرنامج مطلوب</span>}
      <div className="form-group ">
        <div className="rbt-modern-select bg-transparent height-45 text-end mt-100">
          <select
            name="programId"
            className="w-100 text-end "
            style={{ textAlign: "left" }}
            {...register("programId", { required: true })}
          >
            <option value="">اختر البرنامج</option>
            {programs.map((program) => (
              <option value={program.id} key={program.id}>
                {program.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-submit-group mt--100">
        <button
          type="submit"
          className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
        >
          <span className="icon-reverse-wrapper">
            <span className="btn-text">تسجيل</span>
            <span className="btn-icon">
              <i className="feather-arrow-left"></i>
            </span>
            <span className="btn-icon">
              <i className="feather-arrow-left"></i>
            </span>
          </span>
        </button>
      </div>
    </form>
  );
}

export default ProgramRegistrationForm;
