import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchBlogs,
  fetchBlog,
  createBlog,
  updateBlog,
  deleteBlog,
} from "../../api/blogApi";

// Async Thunks for API Calls
export const fetchBlogsAsync = createAsyncThunk(
  "blogs/fetchBlogs",
  async () => {
    const response = await fetchBlogs();
    return response;
  }
);

export const fetchBlogAsync = createAsyncThunk(
  "blogs/fetchBlog",
  async (blogId) => {
    const response = await fetchBlog(blogId);
    return response;
  }
);

export const createBlogAsync = createAsyncThunk(
  "blogs/createBlog",
  async (postData) => {
    const response = await createBlog(postData);
    return response;
  }
);

export const updateBlogAsync = createAsyncThunk(
  "blogs/updateBlog",
  async (updatedBlogData) => {
    const response = await updateBlog(updatedBlogData.id, updatedBlogData);
    return response;
  }
);

export const deleteBlogAsync = createAsyncThunk(
  "blogs/deleteBlog",
  async (blogId) => {
    await deleteBlog(blogId);
    return blogId; // Return the deleted blog ID for removal from state
  }
);

// Slice
const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch blogs
      .addCase(fetchBlogsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch blog
      .addCase(fetchBlogAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs.push(action.payload);
      })
      .addCase(fetchBlogAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create blog
      .addCase(createBlogAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBlogAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs.push(action.payload);
      })
      .addCase(createBlogAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update blog
      .addCase(updateBlogAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBlogAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedBlogData = action.payload;
        const index = state.blogs.findIndex(
          (blog) => blog.id === updatedBlogData.id
        );
        if (index !== -1) {
          state.blogs[index] = { ...state.blogs[index], ...updatedBlogData };
        }
      })
      .addCase(updateBlogAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete blog
      .addCase(deleteBlogAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBlogAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const deletedBlogId = action.payload;
        state.blogs = state.blogs.filter((blog) => blog.id !== deletedBlogId);
      })
      .addCase(deleteBlogAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogsSlice.reducer;
