import React from "react";
import { Link } from "react-router-dom";

export default function MainSection() {
  return (
    <div class="rbt-banner-5 height-650 bg_image bg_image--19">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="inner text-end">
              <h2 class="title text-end">
              متعة التعلم!
              </h2>
             <p class="description text-end">
              برامج تعليمية متنوعة للأطفال والنشء ...<br/> من الثقافة العامة إلى العلوم والتكنولوجيا  
              </p> 
              <div class="slider-btn rbt-button-group justify-content-start" >
                <Link
                  className="rbt-btn btn-border icon-hover radius-round " id="explore"
                  to="/programs/"
                >
                  <span class="btn-text"> استكشف برامجنا</span>
                  <span class="btn-icon" >
                    <i class="feather-arrow-left"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
