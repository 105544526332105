import React from "react";
import BlogCard from "../components/BlogCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchBlogsAsync,
} from "../features/blogs/blogsSlice";

export default function BlogSection() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogs.blogs);
  React.useEffect(() => {
    dispatch(fetchBlogsAsync());
  }, [dispatch]);
  if(!blogs) return null
  let latestBlogs = blogs.slice(blogs.length - 3, blogs.length);
  return (
    <div class="rbt-rbt-blog-area rbt-section-gap bg-color-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center">
              <span class="subtitle bg-secondary-opacity">أخبار تهمك</span>
              <h2 class="title">أحدث المدونات</h2>
              <p class="description has-medium-font-size mt--20">
                تعرف على أحدث المقالات والأخبار التي تهمك
              </p>
            </div>
          </div>
        </div>
        <div class="row g-5 mt--30">
            { latestBlogs.map(blog => ( <BlogCard blog={blog} key={blog.id} />)) }
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="load-more-btn mt--60 text-center">
              <Link class="rbt-btn rbt-marquee-btn" to="/blogs" onClick={()=>window.scroll(0,0)}>
                <span data-text="View All Post"> تصفح المزيد </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
