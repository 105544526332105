import React from "react";
import { useForm } from "react-hook-form";
import BreadCrumb from "../components/BreadCrumb";
export default function ContactUsPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data); // You can handle form submission here
  };
  return (
    <div>
      <BreadCrumb label={"تواصل معنا"} />
      <div class="rbt-conatct-area rbt-section-gap">
        <div class="container">
          <div class="row g-5">
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate"
              data-sal="slide-up"
              data-sal-delay="150"
              data-sal-duration="800"
            >
              <div class="rbt-address">
                <div class="icon">
                  <i class="feather-phone"></i>
                </div>
                <div class="inner">
                  <h5 class="title">أرقام التليفون</h5>
                  <p>
                    <a href="https://wa.me/+201019999357" target="_blank">
                      01019999357
                    </a>
                  </p>
                  <p>
                    <a href="https://wa.me/+201123655002" target="_blank">
                      01123655002
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="800"
            >
              <div class="rbt-address">
                <div class="icon">
                  <i class="feather-mail"></i>
                </div>
                <div class="inner">
                  <h5 class="title">الإيميل</h5>
                  <p>
                    <a href="mailto:center@mahatech.org">center@mahatech.org</a>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate"
              data-sal="slide-up"
              data-sal-delay="250"
              data-sal-duration="800"
            >
              <div class="rbt-address">
                <div class="icon">
                  <i class="feather-map-pin"></i>
                </div>
                <div class="inner">
                  <h5 class="title">العنوان</h5>
                  <p>
                    مبنى أكاديمية مهاتك - شارع (1) <br/> زهراء حلوان - القاهرة -
                    بجوار محطة مترو وادي حوف.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rbt-contact-address">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6">
              <div class="thumbnail">
                <img
                  class="w-100 radius-6"
                  src="assets/images/contact.jpg"
                  alt="Contact Images"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="rbt-contact-form contact-form-style-1 max-width-auto">
                <div class="section-title text-end">
                  <span class="subtitle bg-primary-opacity">
                  نحن هنا لمساعدتك  
                  </span>
                </div>
               <h3 class="title"> نحن نرحب بأي استفسارات أو تعليقات قد تكون لديكم. </h3>
                <form
                  id="contact-form"
                  method="POST"
                  action="mail.php"
                  className="rainbow-dynamic-form max-width-auto"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {errors.name && (
                    <span style={{ color: "red" }}>الاسم مطلوب</span>
                  )}
                  <div className="form-group">
                    <input
                      name="name"
                      id="contact-name"
                      type="text"
                      {...register("name", { required: true })}
                    />
                    <label>الاسم *</label>
                    <span className="focus-border"></span>
                  </div>
                  {errors.email && (
                    <span style={{ color: "red" }}>الايميل مطلوب</span>
                  )}
                  <div className="form-group">
                    <input
                      name="email"
                      type="email"
                      {...register("email", { required: true })}
                    />
                    <label>الايميل *</label>
                    <span className="focus-border"></span>
                  </div>
                  {errors.phone && (
                    <span style={{ color: "red" }}>رقم الهاتف مطلوب</span>
                  )}
                  <div className="form-group">
                    <input
                      name="phone"
                      type="tel"
                      {...register("phone", { required: true })}
                    />
                    <label>رقم الهاتف *</label>
                    <span className="focus-border"></span>
                  </div>
                  {errors.message && (
                    <span style={{ color: "red" }}>الرسالة مطلوبة</span>
                  )}
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="contact-message"
                      {...register("message", { required: true })}
                    ></textarea>
                    <label>الرسالة *</label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="form-submit-group">
                    <button
                      type="submit"
                      className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
                    >
                      <span className="icon-reverse-wrapper">
                        <span className="btn-text">إرسال</span>
                        <span className="btn-icon">
                          <i className="feather-arrow-left"></i>
                        </span>
                        <span className="btn-icon">
                          <i className="feather-arrow-left"></i>
                        </span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rbt-google-map bg-color-white rbt-section-gapTop">
        <iframe
          class="w-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.335148590815!2d31.30749177710845!3d29.883442175005108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145836f8c90852d1%3A0xc188c10aa34b58c0!2sMaha%20Tech%20Science%20Preschool!5e0!3m2!1sen!2seg!4v1712587133322!5m2!1sen!2seg"
          width="600"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
