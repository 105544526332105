import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function ArrayInput({ title, placeholder, id, list, setList, shown = 2,row = 4}) {
  const [newText, setNewText] = useState("");

  return (
    <div className="mb--30">
      <Swiper
        slidesPerView={shown}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="plr--15 pt--15 pb--15"
      >
        {list.map((item, index) => (
          <SwiperSlide key={index}>
            <ArrayItemInput
              title={title}
              placeholder={placeholder}
              id={index}
              list={list}
              setList={setList}
              row={row}
            />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <div class="rbt-cat-box rbt-cat-box-1 variation-2">
            <div class="rbt-card variation-01 card-list-1">
              <label style={{ display: "inline-block" }}> اضف عنصر جديد</label>
              <button
                type="button"
                class="rbt-round-btn"
                style={{ float: "left", display: "inline-block" }}
                onClick={() => {
                  setList([...list, newText]);
                  setNewText("");
                }}
              >
                <span>
                  <i class="feather-plus"></i>
                </span>
              </button>
              <div
                className="rbt-card-body pt--0"
                style={{ visibility: "false" }}
              >
                <textarea
                  placeholder="اكتب هنا"
                  class="form-control"
                  rows={row}
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                  disabled= {true}
                ></textarea>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
function ArrayItemInput({ title, placeholder, id, list, setList,row }) {
  return (
    <>
      <div class="rbt-cat-box rbt-cat-box-1 variation-2">
        <div class="rbt-card variation-01 card-list-1">
          <label style={{ display: "inline-block" }}>{id+1}</label>
          <button
            type="button"
            class="rbt-round-btn"
            style={{ float: "left", display: "inline-block" }}
            onClick={() => {
              let temp = [...list];
              temp.splice(id, 1);
              setList(temp);
            }}
          >
            <span>
              <i class="feather-x"></i>
            </span>
          </button>
          <div className="rbt-card-body pt--0">
            <textarea
              placeholder="اكتب هنا"
              class="form-control"
              rows={row}
              value={list[id]}
              onChange={(e) => {
                let temp = [...list];
                temp[id] = e.target.value;
                setList(temp);
              }}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
}
