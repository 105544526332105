import React, { useState,useEffect } from "react";
import { Accordion } from "react-bootstrap";
import FaqForm from "../AdminForms/FaqForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqsAsync } from "../../features/faq/faqSlice";
import Alert from 'react-bootstrap/Alert';

// let faqsTemp = [
//   {
//     id: 1,
//     question: "سؤال 1",
//     answer: "اجابة السؤال 1",
//     visible: true,
//   },
//   {
//     id: 2,
//     question: "سؤال 2",
//     answer: "اجابة السؤال 2",
//     visible: true,
//   },
// ];
// let faqsTemp2 = [
//   {
//     id: 1,
//     question: "سؤdddال 1",
//     answer: "dsadsa السؤال 1",
//     visible: true,
//   },
//   {
//     id: 2,
//     question: "سؤال 2",
//     answer: "اجابة السؤال 2",
//     visible: true,
//   },
// ];
export default function FaqList({toggleError, toggleSuccess}) {
  const [faqs, setFaqs] = useState([]);
  const [nwFaq, setNwFaq] = useState({
    question: "",
    answer: "",
    visible: true,
    id:0
  });


  const dispatch = useDispatch();
  let faqsTemp = useSelector((rootState) => rootState.faq.faq);
  useEffect(() => {
    dispatch(fetchFaqsAsync())
  }
  , [dispatch]);

  useEffect(() => {
    if (faqsTemp) setFaqs(faqsTemp);
  }, [faqsTemp]);

  function addFaq() {
    console.log("Add Faq");
    setFaqs([...faqs, nwFaq]);
  }
  function updateFaq(faq, index) {
    console.log("Update Faq");
    setFaqs([...faqs.slice(0, index), faq, ...faqs.slice(index + 1)]);
  }
  function deleteFaq(index) {
    console.log("Delete Faq");
    setFaqs([...faqs.slice(0, index), ...faqs.slice(index + 1)]);
  }
  return (
    <>
      <div class="section-title text-center mb--25">
        <h2 class="title">الاسئلة شائعة</h2>
      </div>
      <Accordion
        defaultActiveKey="-1"
        className="col-12 mb--20 rbt-accordion-style rbt-accordion-04 accordion"
      >
        <Accordion.Item eventKey="0" className="accordion-item card">
          <Accordion.Header className="accordion-button accordion-header card-header">
            سؤال جديدة
          </Accordion.Header>
          <Accordion.Body>
            <FaqForm
              faq={nwFaq}
              updateFaq={(t) => setNwFaq(t)}
              addFaq={() => addFaq()}
              isNew={true}
              index={-1}
              dispatch={dispatch}
              toggleSuccess={toggleSuccess}
              toggleError={toggleError}
            />
          </Accordion.Body>
        </Accordion.Item>
        {faqs.map((faq, index) => (
          <Accordion.Item
            eventKey={index + 1}
            className="accordion-item card"
            key={index}
          >
            <Accordion.Header className="accordion-button accordion-header card-header">
              {faq.question}
            </Accordion.Header>
            <Accordion.Body>
              <FaqForm
                faq={faq}
                updateFaq={(t) => updateFaq(t, index)}
                deleteFaq={() => deleteFaq(index)}
                isNew={false}
                index={index}
                dispatch={dispatch}
                toggleSuccess={toggleSuccess}
                toggleError={toggleError}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
