import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchJobs,
  fetchJob,
  createJob,
  updateJob,
  deleteJob,
} from "../../api/jobsApi";

// Async Thunks for API Calls
export const fetchJobsAsync = createAsyncThunk("jobs/fetchJobs", async () => {
  const response = await fetchJobs();
  return response;
});

export const fetchJobAsync = createAsyncThunk(
  "jobs/fetchJob",
  async (jobId) => {
    const response = await fetchJob(jobId);
    return response;
  }
);

export const createJobAsync = createAsyncThunk(
  "jobs/createJob",
  async (postData) => {
    const response = await createJob(postData);
    return response;
  }
);

export const updateJobAsync = createAsyncThunk(
  "jobs/updateJob",
  async (updatedJobData) => {
    const response = await updateJob(updatedJobData.id, updatedJobData);
    return response;
  }
);

export const deleteJobAsync = createAsyncThunk(
  "jobs/deleteJob",
  async (jobId) => {
    await deleteJob(jobId);
    return jobId; // Return the deleted job ID for removal from state
  }
);

// Slice
const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch jobs
      .addCase(fetchJobsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(fetchJobsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch job
      .addCase(fetchJobAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs.push(action.payload);
      })
      .addCase(fetchJobAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create job
      .addCase(createJobAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createJobAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs.push(action.payload);
      })
      .addCase(createJobAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update job
      .addCase(updateJobAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJobAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedJobData = action.payload;
        const index = state.jobs.findIndex(
          (job) => job.id === updatedJobData.id
        );
        if (index !== -1) {
          state.jobs[index] = { ...state.jobs[index], ...updatedJobData };
        }
      })
      .addCase(updateJobAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete job
      .addCase(deleteJobAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteJobAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const deletedJobId = action.payload;
        state.jobs = state.jobs.filter((job) => job.id !== deletedJobId);
      })
      .addCase(deleteJobAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default jobsSlice.reducer;
