import React from "react";
import CareerCard from "../components/CareerCard";
import BreadCrumb from "../components/BreadCrumb";
import { fetchJobsAsync } from "../features/jobs/jobsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function CareersPage() {
  // const careers = [
  //   {
  //     positionName: "مدير فرع",
  //     jobDescription: "احلي دسكربشن علي فخادك",
  //     requirements: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     responsibilities: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     gender: "male",
  //     visible: true,
  //   },
  //   {
  //     positionName: "مدير فرع",
  //     jobDescription: "احلي دسكربشن علي فخادك",
  //     requirements: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     responsibilities: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     gender: "male",
  //     visible: true,
  //   },
  //   {
  //     positionName: "مدير فرع",
  //     jobDescription: "احلي دسكربشن علي فخادك",
  //     requirements: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     responsibilities: [
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //       "Lorem ipsum dolor sit amet, consectetur elit",
  //       "Lorem ipsum dolor sit amet, consectetur",
  //     ],
  //     gender: "male",
  //     visible: true,
  //   },
  // ];
  let careers = useSelector((state) => state.jobs.jobs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchJobsAsync());
  }, [dispatch]);
  return (
    <div>
      <BreadCrumb label={"الوظائف المتاحة"} />
      <div className="rbt-course-details-area rbt-section-gap">
        <div
          style={{ display: "flex", flexDirection: "column", gap: "3rem" }}
          className="container"
        >
          {careers.map((career, index) => (
            <CareerCard
              key={index}
              index={index}
              positionName={career.name}
              jobDescription={career.description}
              requirements={career.requirements}
              responsibilities={career.responsibilities}
              gender={career.gender}
              visible={career.visible}
            />
          ))}
        </div>
        {!careers.length && (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            class="about-btn mt--40"
          >
            <Link
              class="rbt-btn btn-gradient hover-icon-reverse"
              to="/jobRegistration"
            >
              <span class="icon-reverse-wrapper">
                <span class="btn-text">APPLY NOW</span>
                <span class="btn-icon">
                  <i class="feather-arrow-left"></i>
                </span>
                <span class="btn-icon">
                  <i class="feather-arrow-left"></i>
                </span>
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
