export default function FAQAccordionCard({ faq, index }) {
  return (
    <div class="accordion-item card">
      <h2 class="accordion-header card-header" id={`headingThree${index}`}>
        <button
          class={`accordion-button ${index !== 0 && "collapsed"} text-end pl--20` }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseThree${index}`}
          aria-expanded={index === 0 ? "true" : "false"}
          aria-controls={`collapseThree${index}`}
        >
          {faq.question}
        </button>
      </h2>
      <div
        id={`collapseThree${index}`}
        class={`accordion-collapse collapse ${index === 0 && "show"}`}
        aria-labelledby={`headingThree${index}`}
        // data-bs-parent="#accordionExamplea1"
      >
        <div class="accordion-body card-body text-end" >{faq.answer}</div>
      </div>
    </div>
  );
}
