import { useState } from "react";


export default function ImagePicker({image,setImage, title, index=-1, id="file", isFile = false}){
    const [preview, setPreview] = useState(image);
    return (
        <div class="col-12 col-md-12 mb--20">
            <label>{title}</label>
            <div class="rbt-button-group">
                <img src={preview} alt="blog" className="imageViewer"/>
                <input
                    className="inputfile"
                    type="file"
                    id={`${id}${index}`}
                    accept="image/*"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setPreview(reader.result);
                            if(!isFile){
                                setImage(reader.result);
                            }
                        };
                        reader.readAsDataURL(file);
                        if(isFile){
                            setImage(file);
                        }
                    }}
                />
                <label htmlFor={`${id}${index}`} className="">
                    <i class="feather-upload-cloud"></i>
                    <span>اختر صورة</span>
                </label>
            </div>
        </div>
    );
}