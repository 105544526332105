import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

export const fetchFaqs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/faq`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch faqs");
  }
};

export const fetchFaq = async (faqId) => {
  try {
    const response = await axios.get(`${BASE_URL}/faq/${faqId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch faqs");
  }
};

export const createFaq = async (faqData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.post(`${BASE_URL}/faq`, faqData);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to create faq");
  }
};

export const updateFaq = async (faqId, updatedFaqData) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.put(
      `${BASE_URL}/faq/${faqId}`,
      updatedFaqData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to update faq");
  }
}

export const deleteFaq = async (faqId) => {
  try {
    // auth token
    let token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Unauthorized");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.delete(`${BASE_URL}/faq/${faqId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to delete faq");
  }
}
