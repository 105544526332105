import { Link } from "react-router-dom";
export default function BlogCard({ blog }) {
  // if(!blog.visible) return null
  let brief = blog.content.slice(0, 70);
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
      <div className="rbt-card variation-02 rbt-hover">
        <div className="rbt-card-img">
          <Link to={`/blogs/${blog.id}`} >
            <img src={blog.titlePhoto} alt="Card image" />
          </Link>
        </div>
        <div className="rbt-card-body ">
          <h5 className="rbt-card-title ">
            <Link to={`/blogs/${blog.id}`}>{blog.title}</Link>
          </h5>
          <p className="rbt-card-text ">{brief}...</p>
          <div className="rbt-card-bottom">
            <Link className="transparent-button" to={`/blogs/${blog.id}`}>
              للمزيد
              <i
                style={{ marginRight: "0.5rem" }}
                className="feather-arrow-left"
              ></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
