// authApi.js
import axios from "./axiosConfig";
const BASE_URL =  "https://api.mahatech.org/api/user";

export const login = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, userData);
    return response; // Assuming the response contains a token
  } catch (error) {
    throw new Error(error.response?.message || "Login failed");
  }
};

export const register = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, userData);
    return response; // Assuming the response contains a token
  } catch (error) {
    throw new Error(error.response?.message || "Registration failed");
  }
};

export const logout = async () => {
  // We may want to send a request to invalidate the token on the server side
  // For simplicity, let's assume logging out only involves removing the token from localStorage
  localStorage.removeItem("token");
};
