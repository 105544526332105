import axios from "./axiosConfig";
// API endpoints
const BASE_URL = "https://api.mahatech.org/api";

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/blog`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch blogs");
  }
};

export const fetchBlog = async (blogId) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/${blogId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to fetch blog");
  }
};

export const createBlog = async (blogData) => {
  try {
    const response = await axios.post(`${BASE_URL}/blog`, blogData);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to create blog");
  }
};

export const updateBlog = async (blogId, updatedBlogData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/blog/${blogId}`,
      updatedBlogData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to update blog");
  }
};

export const deleteBlog = async (blogId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/blog/${blogId}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.message || "Failed to delete blog");
  }
};
