import React from "react";

import Divider from "../components/Divider";

import AboutSection from "../sections/AboutSection";
import BlogSection from "../sections/BlogSection";
import Copyright from "../sections/Copyright";
import ProgramSection from "../sections/ProgramSection";
import FaqSection from "../sections/FaqSection";
import Footer from "../sections/Footer";
import MainSection from "../sections/MainSection";

export default function HomePage() {
  return (
    <div>
      <MainSection />
      <AboutSection />
      <ProgramSection />
      <BlogSection />
      <Divider />
      <FaqSection />
    </div>
  );
}
