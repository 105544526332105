import React from "react";
import AboutSection from "../sections/AboutSection";
import Breadcrumb from "../components/BreadCrumb";
import AboutFeature from "../components/AboutFeature";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function AboutPage() {
  return (
    <>
      <Breadcrumb label={"عن الأكاديمية"} />
      <Foundation />
      <Goals />
      {/* <Vision /> */}
      <Founders />
    </>
  );
}

function Foundation() {
  return (
    <div
      style={{ marginBottom: "3rem" }}
      class="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop"
    >
      <div class="container">
        <div class="row g-5 ">
          <div class="col-lg-6">
            <div class="thumbnail-wrapper">
              <div class="thumbnail image-1">
                <img
                  data-parallax='{"x": 0, "y": -20}'
                  src="assets/images/about/about-08.png"
                  alt="Education Images"
                />
              </div>
              <div class="thumbnail image-2 d-none d-xl-block">
                <img
                  data-parallax='{"x": 0, "y": 60}'
                  src="assets/images/about/about-09.png"
                  alt="Education Images"
                />
              </div>
              <div class="thumbnail image-3 d-none d-md-block">
                <img
                  data-parallax='{"x": 0, "y": 80}'
                  src="assets/images/about/about-07.jpg"
                  alt="Education Images"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="inner pl--50 pl_sm--0 pl_md--0">
              <div class="section-title text-end">
                <span class="subtitle bg-coral-opacity">عن الأكاديمية</span>
                <h2 class="title">أكاديمية مصرية خاصة</h2>
              </div>
              <p class="description mt--30">
                تقدم برامجها وأنشطتها العلمية والتكنولوجية في مجال رسالتها التي
                تتبناها منذ عام 1983" التنمية العلمية والتكنولوجية المبكرة
                للأطفال والنشء" والحاصلة على اعتماد المجلس الوطني للتدريب
                والتعليم وعضو بشعبة مراكز تنمية وتدريب الموارد البشرية بالاتحاد
                العام للغرف التجارية.
              </p>

              <div class="rbt-feature-wrapper mt--40">
                <AboutFeature
                  icon={"feather-target"}
                  color="bg-pink-opacity"
                  title={"الهدف الاستراتيجي"}
                  description={
                    "إكساب الأطفال والنـشء مبكراً المعرفة العلمية والإنسانية والمهارات التكنولوجية والحياتية الأساسية والمناسبة لاحتياجاتهم العمرية وقدراتهم الذاتية علاوة على إكسابهم مهارات التفكير العلمي والإنساني."
                  }
                />
                <AboutFeature
                  icon={"feather-eye"}
                  color="bg-coral-opacity"
                  title={"الرؤية"}
                  description={
                    "تسعى الأكاديمية لتكون الرائدة في تقديم البرامج التعليمية والتدريبية المتميزة والمتطورة في مجالات التنمية العلمية والتكنولوجية المبكرة للأطفال والنشء."
                  }
                />
                <AboutFeature
                  icon={"feather-star"}
                  color="bg-primary-opacity"
                  title={"تتميز الأكاديمية..."}
                  description={
                    "بتقديم برامج متنوعة ومرنة، وتضم مجموعة من المحاضرين المحترفين والمتخصصين في مجالات متعددة. توفر ايضا بيئة تعليمية محفزة وتفاعلية تساعد الطلاب على تطوير مهاراتهم وتحقيق أهداف الاكاديمية."
                  }
                />
              </div>

              <div class="about-btn mt--40">
                <Link
                  class="rbt-btn btn-gradient hover-icon-reverse"
                  to="/about"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span class="icon-reverse-wrapper">
                    <span class="btn-text">تصفح المزيد</span>
                    <span class="btn-icon">
                      <i class="feather-arrow-left"></i>
                    </span>
                    <span class="btn-icon">
                      <i class="feather-arrow-left"></i>
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Vision() {
  return (
    <div class="rbt-about-area about-style-1 bg-color-white rbt-section-gap">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <div class="content">
              <img src="/assets/images/about/about-06.jpg" alt="About Images" />
            </div>
          </div>
          <div
            class="col-lg-6 sal-animate"
            data-sal="slide-up"
            data-sal-duration="700"
          >
            <div class="inner pl--50 pl_sm--5">
              <div class="section-title text-end">
                <span class="subtitle bg-primary-opacity">الاهداف</span>
                <h2 class="title">What is Histudy For You?.</h2>
                <p class="description mt--20">
                  <strong>Histudy educational platform</strong> ipsum dolor sit
                  amet consectetur adipisicing elit. Nam inventore praesentium
                  alias incidunt! Veritatis, necessitatibus fuga dolore tenetur,
                  beatae suscipit fugit est ea perspiciatis quo provident nisi
                  dolor similique architecto nihil.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Goal({ title, description, icon, number }) {
  return (
    <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
      <div class="service-card service-card-6">
        <div class="inner">
          <div class="icon">
            <img src={icon} alt="icons Images" />
          </div>
          <div class="content">
            <h6 class="title">
              <span href="#">{title}</span>
            </h6>
            <p class="description">{description}</p>
          </div>
          <span class="number-text">{number}</span>
        </div>
      </div>
    </div>
  );
}
function Goals() {
  return (
    <div class="rbt-service-area bg-color-extra2 rbt-section-gap">
      <div class="container">
        <div class="row mb--60">
          <div class="col-lg-12">
            <div class="section-title text-center">
              <h2 class="title">ليه تختار أكاديمية مهاتك؟</h2>
              <p class="description mt--30">
                تعتبر الأكاديمية الرائدة في مجال تنمية الطفل والنشء من خلال
                برامجها التعليمية والتدريبية المتميزة والمتطورة.
              </p>
            </div>
          </div>
        </div>
        <div class="row row--15 mt_dec--30">
          <Goal
            title="أسلوب الأكاديمية"
            description="تستخدم الأكاديمية أسلوب البحث العلمي وحل المشكلات كأسلوب أساسي يتمكن من خلاله المشترك الوصول للمفهوم العلمي بنفسه."
            icon="/assets/images/icons/001-bulb.png"
            number="1"
          />
          <Goal
            title="فريق العمل"
            description="تتميز أكاديمية مهاتك بفريق عمل متكامل و مميز من التخصصات الهندسية و العلمية و التربوية."
            icon="/assets/images/icons/003-id-card.png"
            number="2"
          />
          <Goal
            title="تأسيس برامجها"
            description="عشان من أهدافها الأساسية و الإستراتيجية في تأسيس برامجها ان يكون لأولادها دور فعال و مثمر من أجل مستقبل أفضل لهم و للمجتمع."
            icon="/assets/images/icons/005-diamond.png"
            number="3"
          />
          <Goal
            title="استفادة أفضل"
            description="تهتم مهاتك بمساندة أولياء الأمور لأبنائهم من أجل استفادة أفضل من برامجها و ذلك وفقاً للائحة البرنامج."
            icon="/assets/images/icons/004-pass.png"
            number="4"
          />
        </div>
      </div>
    </div>
  );
}
function Founder({ name, title, image, location }) {
  return (
    <div class="col-lg-12 col-md-12 col-12">
      <div class="rbt-team team-style-default style-three rbt-hover">
        <div class="inner">
          <div class="thumbnail">
            <img src={image} alt="Corporate Template" />
          </div>
          <div class="content">
            <h5 class="">{name}</h5>
            <h6 class="subtitle theme-gradient">{title}</h6>
            {/* <span class="team-form">
              <i class="feather-map-pin"></i>
              <span class="location">{location}</span>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
function Founders() {
  return (
    <div class="rbt-team-area bg-color-white rbt-section-gap">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-12 mb--60">
            <div class="section-title text-center">
              <span class="subtitle bg-pink-opacity">المؤسسين </span>
              <h2 class="title"> مؤسسين الأكاديمية </h2>
              <p class="description mt--20">
                تعرف على مؤسسين الأكاديمية والذين ساهموا في تأسيسها وتطويرها
              </p>
            </div>
          </div>
        </div>
        <div class="row g-5">
          <Swiper
           slidesPerView={3}
           spaceBetween={15}
           pagination={{
             clickable: true,
           }}
           navigation={true}
           modules={[Pagination, Navigation]}
           className="plr--30 pt--15 pb--15"
          >
            <SwiperSlide>
              <Founder
                name="م / عبد العاطى بكرى"
                title="مؤسس أكاديمية مهاتك"
                image="/assets/images/founders/EngAbdelatty.png"
                location="CO Miego, AD,USA"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Founder
                name="د / هدى بكرى"
                title="مدير عام أكاديمية مهاتك"
                image="/assets/images/founders/Huda.png"
                location="CO Miego, AD,USA"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Founder
                name="د / عمر بكرى"
                title="مدير عام أكاديمية مهاتك"
                image="/assets/images/founders/DR.Omar.png"
                location="CO Miego, AD,USA"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Founder
                name="م / أسامة بكرى"
                title="مدير عام أكاديمية مهاتك"
                image="/assets/images/founders/Usama.png"
                location="CO Miego, AD,USA"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Founder
                name="د / حبيبة بكرى"
                title="مدير عام أكاديمية مهاتك"
                image="/assets/images/founders/Dr.Habiba.png"
                location="CO Miego, AD,USA"
              />
            </SwiperSlide>
          </Swiper>
          
        </div>
      </div>
    </div>
  );
}
