import React from "react";
import JobRegistrationForm from "../components/JobRegistrationForm";
import ProgramRegistrationForm from "../components/ProgramRegistrationForm";

export default function RegistrationPage({ formType }) {
  return (
    <div>
      <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner text-center">
                <h2 className="title">التسجيل</h2>
                <ul className="page-list">
                  <li className="rbt-breadcrumb-item active">
                    تسجيل {formType === "job" ? "للوظيفه" : "البرنامج"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-elements-area bg-color-white rbt-section-gap">
        <div className="container">
          <div
            className="row gy-5 row--30"
            style={{
              justifyContent: "center",
            }}
          >
            <div className="col-lg-6">
              <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                <h3 className="title">التسجيل</h3>
                {formType === "job" ? (
                  <JobRegistrationForm />
                ) : (
                  <ProgramRegistrationForm />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
