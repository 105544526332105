import React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsAsync } from "../features/jobs/jobsSlice";
import {
  createApplicantAsync,
  fetchApplicantsAsync,
} from "../features/applicant/applicantSlice";
import { Alert } from "react-bootstrap";

function JobRegistrationForm() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  function toggleSuccess() {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  }
  function toggleError() {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }
  const [jobs, setJobs] = useState([]);
  const dispatch = useDispatch();
  let jobsTemp = useSelector((rootState) => rootState.jobs.jobs);

  useEffect(() => {
    dispatch(fetchJobsAsync()).then((res) => {
      if (res.payload) setJobs(res.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    if (jobsTemp) setJobs(jobsTemp);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    dispatch(createApplicantAsync(data)).then((res) => {
      if (res.payload) {
        toggleSuccess();
        e.target.reset();
      } else {
        toggleError();
      }
    });
  };

  return (
    <form className="max-width-auto" onSubmit={handleSubmit(onSubmit)}>
      {showSuccess && <Alert variant="success">تم التسجيل بنجاح</Alert>} 
      {showError && <Alert variant="danger">فشل التسجيل</Alert>}
    
      {errors.name && <span style={{ color: "red" }}>اسم مطلوب</span>}
      <div className="form-group">
        <input
          name="name"
          type="text"
          {...register("name", { required: true })}
        />
        <label>اسم *</label>
        <span className="focus-border"></span>
      </div>

      {errors.birthDate && (
        <span style={{ color: "red" }}>تاريخ الميلاد مطلوب</span>
      )}
      <br />
      <label>تاريخ الميلاد *</label>
      <div className="form-group">
        <input
          name="birthDate"
          type="date"
          {...register("birthDate", { required: true })}
        />
        <span className="focus-border"></span>
      </div>

      {errors.gender && <span style={{ color: "red" }}> النوع مطلوب</span>}
      <div className="form-group">
        <div className="rbt-modern-select bg-transparent height-45 text-end mt-100">
          <select
            className="w-100 text-end "
            name="gender"
            {...register("gender", { required: true })}
          >
            <option value="">اختر النوع</option>
            <option value="0"> ذكر</option>
            <option value="1"> مؤنث</option>
          </select>
        </div>
      </div>

      {errors.jobId && <span style={{ color: "red" }}>الوظيفة مطلوبة</span>}
      <div className="form-group ">
        <div className="rbt-modern-select bg-transparent height-45 text-end mt-100">
          <select
            className="w-100 text-end "
            style={{ textAlign: "left" }}
            name="JobId"
            {...register("JobId")}
          >
            <option value="">اختر الوظيفة</option>
            {jobs.map((job) => (
              <option key={job.id} value={job.id}>
                {job.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {errors.cv && <span style={{ color: "red" }}>السي في مطلوب </span>}
      <br />
      <div className="form-group">
        <input
          name="cv"
          {...register("cv", { required: true })}
          type="file"
          placeholder="السي في"
        />
        <span className="focus-border"></span>
      </div>

      <div className="form-submit-group">
        <button
          type="submit"
          className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
        >
          <span className="icon-reverse-wrapper">
            <span className="btn-text">تسجيل</span>
            <span className="btn-icon">
              <i className="feather-arrow-left"></i>
            </span>
            <span className="btn-icon">
              <i className="feather-arrow-left"></i>
            </span>
          </span>
        </button>
      </div>
    </form>
  );
}

export default JobRegistrationForm;
