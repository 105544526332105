import React from "react";

import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchProgramAsync } from "../features/programs/programsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export default function ProgramDetailsPage() {
  const { id } = useParams();
  let program = useSelector((rootState) => rootState.programs.programDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProgramAsync(id));
  }, [dispatch, id]);

  if (!program) return <div>Loading...</div>;

  return (
    <div>
      <div class="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div class="breadcrumb-inner">
          <img src="/assets/images/bg/bg-image-10.jpg" alt="Education Images" />
        </div>
        <div class="rbt-banner-image"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="content text-end">
                <ul class="page-list">
                  <li class="rbt-breadcrumb-item">
                    <a href="/">الرائيسية</a>
                  </li>
                  <li>
                    <div class="icon-right">
                      <i class="feather-chevron-left"></i>
                    </div>
                  </li>
                  <li class="rbt-breadcrumb-item">
                    <a href="/programs">البرامج</a>
                  </li>
                  <li>
                    <div class="icon-right">
                      <i class="feather-chevron-left"></i>
                    </div>
                  </li>
                  <li class="rbt-breadcrumb-item active">اسم البرنامج</li>
                </ul>
                <h2 class="title">اسم البرنامج</h2>
                <p class="description">
                  تعلم البرنامج الذي تريده واحصل على شهادة معتمدة
                </p>

                <div class="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">
                  {/* <div class="feature-sin best-seller-badge">
                                <span class="rbt-badge-2">
                                    <span class="image"><img src="/assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> Bestseller
                                </span>
                            </div>

                            <div class="feature-sin rating">
                                <a href="#">4.8</a>
                                <a href="#"><i class="fa fa-star"></i></a>
                                <a href="#"><i class="fa fa-star"></i></a>
                                <a href="#"><i class="fa fa-star"></i></a>
                                <a href="#"><i class="fa fa-star"></i></a>
                                <a href="#"><i class="fa fa-star"></i></a>
                            </div> */}

                  <div class="feature-sin total-rating">
                    <a class="rbt-badge-4" href="#">
                      215,475 طالب
                    </a>
                  </div>

                  {/* <div class="feature-sin total-student">
                                <span>616,029 students</span>
                            </div> */}
                </div>

                {/* <div class="rbt-author-meta mb--20">
                            <div class="rbt-avater">
                                <a href="#">
                                    <img src="/assets/images/client/avatar-02.png" alt="Sophia Jaymes"/>
                                </a>
                            </div>
                            <div class="rbt-author-info">
                                By <a href="profile.html">Angela</a> In <a href="#">Development</a>
                            </div>
                        </div>

                        <ul class="rbt-meta">
                            <li><i class="feather-calendar"></i>Last updated 12/2024</li>
                            <li><i class="feather-globe"></i>English</li>
                            <li><i class="feather-award"></i>Certified Course</li>
                        </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rbt-course-details-area ptb--60">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-8">
              <div class="course-details-content">
                <div class="rbt-inner-onepage-navigation sticky-top mt--30 ">
                  <nav class="mainmenu-nav onepagenav">
                    <ul class="mainmenu">
                      <li class="current">
                        <a href="#overview">تعريف البرنامج</a>
                      </li>
                      <li>
                        <a href="#coursecontent">الانشطة</a>
                      </li>
                      <li>
                        <a href="#details">الأهداف والمتطلبات</a>
                      </li>
                      <li>
                        <a href="#steps">خطوات التسجيل</a>
                      </li>
                      <li>
                        <a href="#gallery">البوم صور</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div
                  class="rbt-course-feature-box overview-wrapper rbt-shadow-box mt--30 has-show-more"
                  id="overview"
                >
                  <div class="rbt-course-feature-inner has-show-more-inner-content">
                    <div class="section-title">
                      <h4 class="rbt-title-style-3">تعريف البرنامج</h4>
                    </div>
                    <p>{program.definition}</p>
                    <p>{/* hey */}</p>
                  </div>
                  {/* <div class="rbt-show-more-btn">Show More</div> */}
                </div>

                <div
                  class="course-content rbt-shadow-box coursecontent-wrapper mt--30"
                  id="coursecontent"
                >
                  <div class="rbt-course-feature-inner">
                    <div class="section-title">
                      <h4 class="rbt-title-style-3">الأنشطة</h4>
                    </div>
                    <div class="rbt-accordion-style rbt-accordion-02 accordion">
                      <Accordion
                        defaultActiveKey="-1"
                        className="rbt-accordion-style rbt-accordion-02 accordion"
                        // remove button from accordion
                        // as it is not needed in this context
                      >
                        {program.activities.map((activity, index) => (
                          <Accordion.Item
                            eventKey="0"
                            className="accordion-item card"
                          >
                            <Accordion.Header className="accordion-button accordion-header card-header">
                              <h6 class="accordion-title card-title">
                                {" "}
                                {activity}{" "}
                              </h6>
                            </Accordion.Header>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div
                  class="rbt-course-feature-box rbt-shadow-box details-wrapper mt--30"
                  id="details"
                >
                  <div class="row g-5">
                    <div class="col-lg-6">
                      <div class="section-title">
                        <h4 class="rbt-title-style-3 mb--20">الاهداف</h4>
                      </div>
                      <ul class="rbt-list-style-1">
                        {program.goals.map((goal, index) => (
                          <li key={index}>
                            <i class="feather-check"></i>
                            {goal}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div class="col-lg-6">
                      <div class="section-title">
                        <h4 class="rbt-title-style-3 mb--20">المتطلبات</h4>
                      </div>
                      <ul class="rbt-list-style-1">
                        {program.requirements.map((requirement, index) => (
                          <li key={index}>
                            <i class="feather-check"></i>
                            {requirement}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="rbt-course-feature-box rbt-shadow-box details-wrapper mt--30"
                  id="steps"
                >
                  <div class="row g-5">
                    <div class="col-lg-12">
                      <div class="section-title">
                        <h4 class="rbt-title-style-3 mb--20">خطوات التسجيل</h4>
                      </div>
                      <ol
                        class="rbt-list-style-1"
                        style={{ listStyle: "decimal" }}
                      >
                        {program.registrationSteps.map((step, index) => (
                          <li>
                            <span style={{ marginLeft: "10px" }}>
                              {index + 1}.
                            </span>{" "}
                            {step}
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>

                <div
                  id="gallery"
                  class="rbt-course-feature-box rbt-shadow-box thuumbnail mt--30"
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-title">
                          <h4 class="rbt-title-style-3 mb--20">البوم الصور</h4>
                        </div>
                        <div class="swiper rbt-gif-banner-area rbt-arrow-between">
                          <div class="swiper-wrapper">
                          <div class="swiper-slide">
                                <div class="thumbnail" style={{textAlign:"center"}}>
                                  <a>
                                    <img
                                      class="rbt-radius imageViewer "
                                      src={program.thumbnailPhoto}
                                      alt="Banner Images"
                                    />
                                  </a>
                                </div>
                              </div>
                            {program.gallery.map((image, index) => (
                              <div class="swiper-slide" key={index}>
                                <div class="thumbnail" style={{textAlign:"center"}}>
                                  <a>
                                    <img
                                      class="rbt-radius imageViewer "
                                      src={image}
                                      alt="Banner Images"
                                    />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div class="rbt-swiper-arrow rbt-arrow-left">
                            <div class="custom-overfolow">
                              <i class="rbt-icon feather-arrow-left"></i>
                              <i class="rbt-icon-top feather-arrow-left"></i>
                            </div>
                          </div>

                          <div class="rbt-swiper-arrow rbt-arrow-right">
                            <div class="custom-overfolow">
                              <i class="rbt-icon feather-arrow-right"></i>
                              <i class="rbt-icon-top feather-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="course-sidebar sticky-top rbt-shadow-box course-sidebar-top rbt-gradient-border">
                <div class="inner">
                  <div class="content-item-content">
                    {/* <div class="rbt-price-wrapper d-flex flex-wrap align-items-center justify-content-between">
                      <div class="rbt-price">
                        <span class="current-price">$60.99</span>
                        <span class="off-price">$84.99</span>
                      </div>
                      <div class="discount-time">
                        <span class="rbt-badge color-danger bg-color-danger-opacity">
                          <i class="feather-clock"></i> 3 days left!
                        </span>
                      </div>
                    </div> */}

                    <div class="add-to-card-button mt--15">
                      <a
                        class="rbt-btn btn-gradient icon-hover w-100 d-block text-center"
                        href="#"
                      >
                        <span class="btn-text">سجل معنا </span>
                        <span class="btn-icon">
                          <i class="feather-arrow-right"></i>
                        </span>
                      </a>
                    </div>

                    <span class="subtitle">
                      {/* <i class="feather-rotate-ccw"></i> 30-Day Money-Back
                      Guarantee */}
                    </span>

                    <div class="rbt-widget-details has-show-more">
                      <ul class="has-show-more-inner-content rbt-course-details-list-wrapper">
                        <li>
                          <span>موسم</span>
                          <span class="rbt-feature-value rbt-badge-5">
                            {program.season}
                          </span>
                        </li>
                        <li>
                          <span> من عمر</span>
                          <span class="rbt-feature-value rbt-badge-5">
                            {program.eligibleAgeFrom}
                            {program.eligibleAgeFrom > 10 ? " اعوام" : " عام"}
                          </span>
                        </li>
                        <li>
                          <span>الى عمر</span>
                          <span class="rbt-feature-value rbt-badge-5">
                            {program.eligibleAgeTo}
                            {program.eligibleAgeFrom > 10 ? " اعوام" : " عام"}
                          </span>
                        </li>
                        <li>
                          <span>عدد الساعات</span>
                          <span class="rbt-feature-value rbt-badge-5">
                            {program.hours}
                          </span>
                        </li>
                        <li>
                          <span>سعر يبدا من</span>
                          <span class="rbt-feature-value rbt-badge-5">
                            {program.price} جنية مصري
                          </span>
                        </li>
                      </ul>
                      {/* <div class="rbt-show-more-btn">Show More</div> */}
                    </div>

                    <div class="social-share-wrapper mt--30 text-center">
                      <div class="rbt-post-share d-flex align-items-center justify-content-center">
                        <ul class="social-icon social-default transparent-with-border justify-content-center">
                          <li>
                            <a href="https://www.facebook.com/">
                              <i class="feather-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.twitter.com">
                              <i class="feather-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/">
                              <i class="feather-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkdin.com/">
                              <i class="feather-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <hr class="mt--20" />
                      <div class="contact-with-us text-center">
                        <p>لتفاصيل اكثر</p>
                        <p class="rbt-badge-2 mt--10 justify-content-center w-100">
                          <i class="feather-phone mr--5"></i>
                          {"   "} اتصل بنا:{"   "}
                          <a href="#">
                            <strong> 01210648613 </strong>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rbt-separator-mid">
        <div class="container">
          <hr class="rbt-separator m-0" />
        </div>
      </div>
    </div>
  );
}
