import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImagePicker from "./ImagePicker";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function ArrayInput({ title, shown = 1, list, setList, id,defaultImage }) {
  const [newImage, setNewText] = useState(
    defaultImage
  );
  return (
    <div className="mb--30">
      <Swiper
        slidesPerView={shown}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="plr--15 pt--15 pb--15"
      >
        {(list ? (list.map((item, index) => ( 
        <SwiperSlide key={index}>
            <ArrayItemInput
              title={title}
              index={index}
              list={list}
              setList={setList}
              id={id}
            />
          </SwiperSlide>
        ))) : (<></>))}

        <SwiperSlide>
          <div class="rbt-cat-box rbt-cat-box-1 variation-2">
            <div class="rbt-card variation-01 card-list-1">
              <label style={{ display: "inline-block" }}> اضف عنصر جديد</label>
              <button
                type="button"
                class="rbt-round-btn"
                style={{ float: "left", display: "inline-block" }}
                onClick={() => {
                  if(list == null)
                    setList([newImage]);
                  else
                    setList([...list, newImage]);
                  setNewText(defaultImage);
                }}
              >
                <span>
                  <i class="feather-plus"></i>
                </span>
              </button>
              <div
                className="rbt-card-body pt--0"
                style={{ visibility: "false" }}
              >
                <ImagePicker
                  title={""}
                  image={newImage}
                  setImage={setNewText}
                  index={-1}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
function ArrayItemInput({ title, index, list, setList, row, id }) {
  return (
    <>
      <div class="rbt-cat-box rbt-cat-box-1 variation-2">
        <div class="rbt-card variation-01 card-list-1">
          <label style={{ display: "inline-block" }}>{index + 1}</label>
          <button
            type="button"
            class="rbt-round-btn"
            style={{ float: "left", display: "inline-block" }}
            onClick={() => {
              if(list != null)
              {
                let temp = [...list];
                temp.splice(index, 1);
                setList(temp);
              }else{
              }
              }}
          >
            <span>
              <i class="feather-x"></i>
            </span>
          </button>
          <div className="rbt-card-body pt--0">
            <ImagePicker
              title={""}
              image={list[index]}
              setImage={(image) => {
                let temp = [...list];
                temp[index] = image;
                setList(temp);
              }}
              id= {id}
              index={index}
              isFile = {true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
