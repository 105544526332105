export default function AboutFeature({icon, color, title, description}) {
  return (
    <div class="rbt-feature feature-style-1">
      <div class={`icon ${color}`}>
        <i class={icon}></i>
      </div>
      <div class="feature-content">
        <h6 class="feature-title">{title}</h6>
        <p class="feature-description">
            {description}  
        </p>
      </div>
    </div>
  );
}
