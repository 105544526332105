import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPrograms,
  fetchProgram,
  createProgram,
  updateProgram,
  deleteProgram,
} from "../../api/programsApi";

export const fetchProgramsAsync = createAsyncThunk(
  "programs/fetchPrograms",
  async () => {
    return await fetchPrograms();
  }
);
export const fetchProgramAsync = createAsyncThunk(
  "programs/fetchProgram",
  async (programId) => {
    return await fetchProgram(programId);
  }
);

export const createProgramAsync = createAsyncThunk(
  "programs/createProgram",
  async (postData) => {
    return await createProgram(postData);
  }
);

export const updateProgramAsync = createAsyncThunk(
  "programs/updateProgram",
  async (updatedProgramData) => {
    return await updateProgram(updatedProgramData.id, updatedProgramData);
  }
);

export const deleteProgramAsync = createAsyncThunk(
  "programs/deleteProgram",
  async (programId) => {
    return await deleteProgram(programId);
  }
);

const programsSlice = createSlice({
  name: "programs",
  initialState: {
    programs: [],
    status: "idle",
    error: null,
    programDetails: undefined,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch Programs
      .addCase(fetchProgramsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProgramsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programs = action.payload;
      })
      .addCase(fetchProgramsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Program
      .addCase(fetchProgramAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProgramAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programDetails = action.payload;
      })
      .addCase(fetchProgramAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create Program
      .addCase(createProgramAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProgramAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programs.push(action.payload);
      })
      .addCase(createProgramAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update Program
      .addCase(updateProgramAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProgramAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programs = state.programs.map((program) =>
          program.id === action.payload.id ? action.payload : program
        );
      })
      .addCase(updateProgramAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete Program
      .addCase(deleteProgramAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProgramAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programs = state.programs.filter(
          (program) => program.id !== action.payload.id
        );
      })
      .addCase(deleteProgramAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default programsSlice.reducer;
