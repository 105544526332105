import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchApplicants,
  createApplicant,
} from "../../api/applicantApi";

// Async Thunks for API Calls
export const fetchApplicantsAsync = createAsyncThunk(
  "Applicant/fetchApplicants",
  async () => {
    const response = await fetchApplicants();
    return response;
  }
);

export const createApplicantAsync = createAsyncThunk(
  "Applicant/createApplicant",
  async (postData) => {
    const response = await createApplicant(postData);
    return response;
  }
);

// Slice

const ApplicantSlice = createSlice({
  name: "Applicant",
  initialState: {
    Applicant: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) =>{
    builder
      // Fetch Program Registrations
      .addCase(fetchApplicantsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApplicantsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Applicant = action.payload;
      })
      .addCase(fetchApplicantsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create Program Registration
      .addCase(createApplicantAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createApplicantAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Applicant.push(action.payload);

      })
      .addCase(createApplicantAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;

      })
  },
});

export default ApplicantSlice.reducer;
