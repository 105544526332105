import React from "react";
import ProgramCardInGrid from "../components/ProgramCardInGrid";
import { useState } from "react";
import {fetchProgramsAsync} from "../features/programs/programsSlice"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function ProgramsPage() {
  // let programs = [
  //   {
  //     id: 1,
  //     name: "نادي علوم ببساطة",
  //     hours: 420,
  //     students: 600,
  //     price: 60,
  //     offPrice: 120,
  //     rating: 5,
  //     reviews: 15,
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/bebasata.png",
  //     description: "للأطفال من (5-3 سنوات) مرحلة ما قبل الروضة",
  //   },
  //   {
  //     id: 2,
  //     name: "نادي علوم ابن الهيثم",
  //     hours: 216,
  //     students: 2000,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "اربع مستويات",
  //     season : "شتوي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/IBN-ElHaitham.jpg",
  //     description: "للإستضاقة العلمية المرحة  للاطفال من (5-8 سنوات)",
  //   },
  //   {
  //     id: 3,
  //     name: "نادي علوم مشرفة",
  //     hours: 144,
  //     students: 2000,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/Moushrafa2.jpg",
  //     description: "للمفاهيم والمهارات الاساسية للفيزياء والرياضيات والتكنولوجيا للأطفال من (9-12 سنة)",
  //   },
  //   {
  //     id: 4,
  //     name: "مشرفة الحر",
  //     hours: 144,
  //     students: 100,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "مستوي واحد ",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/Mousharfa-7or.jpg" ,
  //     description: "العلوم التجريبية و التقنية و تطبيقتها لطلاب المرحلة اللإعدادية",
  //   },
  //   {
  //     id: 5,
  //     name: "نادي علوم الصقور الشابة",
  //     hours: 150,
  //     students: 800,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/Squor.jpg",
  //     description: "برنامج متخصص في نظم الميكاترونيات والنظم المدمجة",
  //   },
  //   {
  //     id: 6,
  //     name: "برنامج الخوارزمي للرياضيات ومبادئ البرمجة ",
  //     hours: 70,
  //     students: 120,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/dummyCourses/Khwarzmii.jpg",
  //     description: "يحتوي البرنامج على أنشطة متنوعة وممتعة ـ ـ ـ متدرجة المحتوى والمهارة والمناسبة لقد ارتهم وفقا للمعايير العالمية",
  //   },
  //   {
  //     id: 5,
  //     name: "نادي علوم الصقور الشابة",
  //     hours: 10,
  //     students: 30,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     image: "/assets/images/course/course-online-02.jpg",
  //     description: "برنامج متخصص في نظم الميكاترونيات والنظم المدمجة",
  //   },
  //   {
  //     id: 5,
  //     name: "نادي علوم الصقور الشابة",
  //     hours: 10,
  //     students: 30,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     thumbnailPhoto: "/assets/images/course/course-online-02.jpg",
  //     brief: "برنامج متخصص في نظم الميكاترونيات والنظم المدمجة",
  //   },
  //   {
  //     id: 5,
  //     name: "نادي علوم الصقور الشابة",
  //     hours: 10,
  //     students: 30,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     thumbnailPhoto: "/assets/images/course/course-online-02.jpg",
  //     brief: "برنامج متخصص في نظم الميكاترونيات والنظم المدمجة",
  //   },
  //   {
  //     id: 5,
  //     name: "نادي علوم الصقور الشابة",
  //     hours: 10,
  //     students: 30,
  //     price: 20,
  //     offPrice: 43,
  //     rating: 5,
  //     reviews: 15,
  //     levels : "ثلاث مستويات",
  //     season : "صيفي",
  //     type: "ScientificClub",
  //     thumbnailPhoto: "/assets/images/course/course-online-02.jpg",
  //     brief: "برنامج متخصص في نظم الميكاترونيات والنظم المدمجة",
  //   },
  // ];
  let [filter, setFilter] = useState("all");
  let programs = useSelector((rootState) => rootState.programs.programs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProgramsAsync());
  }, [dispatch]);
  return (
    <div>
      <div className="rbt-page-banner-wrapper">
        <div className="rbt-banner-image"></div>

        <div className="rbt-banner-content">
          <div style={{ marginTop: "7rem" }} className="rbt-banner-content-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="page-list">
                    <li className="rbt-breadcrumb-item">
                      <a href="index.html">الرئيسية</a>
                    </li>
                    <li>
                      <div className="icon-left">
                        <i className="feather-chevron-left"></i>
                      </div>
                    </li>
                    <li className="rbt-breadcrumb-item active">البرامج</li>
                  </ul>

                  <div className=" title-wrapper">
                    {/* <a href="#" className="rbt-badge-2">
                      50 برنامج<div className="image">🎉</div>
                    </a> */}
                    <h1 className="title mb--0">البرامج</h1>
                  </div>

                  <p className="description">
                    {filter === "all" && "تنقسم برامج مهاتك التي تقدمها من خلال أنديتها العلمية إلى نوعين نوادي علوم و برامج حرة."}
                    {filter === "ScientificClub" && "البرامج التي تستمر برامجها لعدد من السنوات والتي تتسلسل وتتدرج برامجها من حيث المستوى والمحتوى والعمق حسب الفئة العمرية."}
                    {filter === "StandaloneProgram" && "البرامج التي ليس من الضروري عقدها كل عام وكذلك ليس لها مواعيد محددة وثابتة سنوية، حيث أنها تتغير حسب جدول برامج الأكاديمية فقد تعقد خلال فترة الصيف أو الترم الأول أو الثاني أو خلال إجازة نصف العام."}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rbt-course-top-wrapper mt--40">
            <div className="container">
              <div className="row g-5 align-items-center">
                <div className="col-lg-12 mt--60">
                  <ul
                    className="rbt-portfolio-filter filter-tab-button justify-content-start nav nav-tabs"
                    id="rbt-myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="active"
                        id="all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all"
                        type="button"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                        onClick={() => setFilter("all")}
                      >
                        <span className="filter-text">جميع البرامج</span>{" "}
                        {/* <span className="course-number">01</span> */}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        id="featured-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#featured"
                        type="button"
                        role="tab"
                        aria-controls="featured"
                        aria-selected="false"
                        onClick={() => setFilter("ScientificClub")}
                      >
                        <span className="filter-text">نوادي العلوم</span>{" "}
                        {/* <span className="course-number">02</span> */}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        id="popular-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#popular"
                        type="button"
                        role="tab"
                        aria-controls="popular"
                        aria-selected="false"
                        onClick={() => setFilter("StandaloneProgram")}
                      >
                        <span className="filter-text">برامج الحرة</span>{" "}
                        {/* <span className="course-number">03</span> */}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-section-overlayping-top rbt-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="rbt-myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                >
                  <div className="rbt-course-grid-column">
                    {programs.map((program) => (
                      <ProgramCardInGrid program={program} />
                    ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="featured"
                  role="tabpanel"
                  aria-labelledby="featured-tab"
                >
                  <div className="rbt-course-grid-column">
                    {programs
                      .filter((program) => program.programType === "ScientificClub")
                      .map((program) => (
                        <ProgramCardInGrid program={program} />
                      ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="popular"
                  role="tabpanel"
                  aria-labelledby="popular-tab"
                >
                  <div className="rbt-course-grid-column">
                    {programs
                      .filter((program) => program.programType === "StandaloneProgram")
                      .map((program) => (
                        <ProgramCardInGrid program={program} />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
