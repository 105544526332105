import { useState,useEffect } from "react";
import { Accordion } from "react-bootstrap";
import BlogForm from "../AdminForms/BlogForm";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlogsAsync,
} from "../../features/blogs/blogsSlice";

export default function BlogList({toggleError, toggleSuccess}) {
  const [blogs, setBlogs] = useState([]);
  const [nwBlog, setNwBlog] = useState({title: "",subtitle:"", content: "", publishDate: "2021-10-10", tags: [], titlePhoto: "/assets/images/course/course-online-02.jpg", visible: true, id: 0});
  const dispatch = useDispatch();
  let blogsTemp = useSelector((rootState) => rootState.blogs.blogs);

  useEffect(() => {
    dispatch(fetchBlogsAsync()).then((res) => {
      if(res.payload)
        setBlogs(res.payload);
    });
  }, [dispatch]);
  useEffect(() => {
    if(blogsTemp)
      setBlogs(blogsTemp);
  }, []);

  function addBlog() {
    console.log("Add Blog");
    setBlogs([...blogs, nwBlog]);
  }
  function updateBlog(blog, index) {
    console.log("Update Blog", index);
    setBlogs([...blogs.slice(0, index), blog, ...blogs.slice(index + 1)]);
  }
  function deleteBlog(index){
    console.log("Delete Blog");
    setBlogs([...blogs.slice(0, index), ...blogs.slice(index + 1)]);
  }
  return (
    <>
      <div class="section-title text-center mb--25">
        <h2 class="title">المدونات</h2>
      </div>
      <Accordion
        defaultActiveKey="-1"
        className="col-12 mb--20 rbt-accordion-style rbt-accordion-04 accordion"
      >
        <Accordion.Item eventKey="0" className="accordion-item card">
          <Accordion.Header className="accordion-button accordion-header card-header">
            مدونة جديدة
          </Accordion.Header>
          <Accordion.Body>
            <BlogForm 
            blog={nwBlog} 
            updateBlog={(t)=>setNwBlog(t)} 
            addBlog={()=>addBlog()}
            dispatch = {dispatch}
            blogs={blogs}
            isNew={true}
            toggleError={toggleError}
            toggleSuccess={toggleSuccess}
            />
          </Accordion.Body>
        </Accordion.Item>

        {blogs.map((blog, index) => (
          <Accordion.Item
            eventKey={index + 1}
            className="accordion-item card"
            key={index}
          >
            <Accordion.Header className="accordion-button accordion-header card-header">
              {blog.title}
            </Accordion.Header>
            <Accordion.Body>
              <BlogForm
                blog={blog}
                updateBlog={(t)=>updateBlog(t, index)}
                deleteBlog={()=>deleteBlog(index)}
                dispatch = {dispatch}
                blogs={blogs}
                index={index}
                isNew={false}
                toggleError={toggleError}
                toggleSuccess={toggleSuccess}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
