import { useState } from "react";
import ProgramList from "../components/AdminLists/ProgramList";
import BlogList from "../components/AdminLists/BlogList";
import FaqList from "../components/AdminLists/FaqList";
import JobList from "../components/AdminLists/JobList";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../features/auth/authSlice";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";


export default function AdminPage() {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  function toggleSuccess() {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  }
  function toggleError() {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

 
  const [page, setPage] = useState("programs");
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.token !== null);
  const onSubmit = (data) => dispatch(loginAsync(data));
  function logout() {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    
    // dispatch({ type: "auth/logout" });
  }

  return (
    <>
      {!isLoggedIn ? (
        <>
          <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner text-center">
                    <h2 className="title">ادارة الموقع</h2>
                    <ul className="page-list">
                      <li className="rbt-breadcrumb-item">
                        <a href="/">الرائيسية</a>
                      </li>
                      <li>
                        <div className="icon-left">
                          <i className="feather-chevron-left"></i>
                        </div>
                      </li>
                      <li className="rbt-breadcrumb-item active">
                        ادارة الموقع
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rbt-elements-area bg-color-extra2 rbt-section-gap">
            <div className="container">
              <div className="row g-5 row--30 justify-content-center ">
                <div className="col-lg-6">
                  <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                    <h3 className="title">تسجيل دخول</h3>
                    <form
                      className="max-width-auto"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-group">
                        <input
                          name="userName"
                          type="text"
                          {...register("userName", {
                            required: true,
                            // pattern: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
                          })}
                        />
                        <label>بريد الكتروني *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className="form-group">
                        <input
                          name="password"
                          type="password"
                          {...register("password", {
                            required: true,
                            minLength: 6,
                          })}
                        />
                        <label> كلمة المرور *</label>
                        <span className="focus-border"></span>
                      </div>

                      <div className="form-submit-group">
                        <input
                          type="submit"
                          className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="rbt-dashboard-area  rbt-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-5">
                    <div className="col-lg-3">
                      <div className="rbt-default-sidebar sticky-top rbt-shadow-box rbt-gradient-border">
                        <div className="inner">
                          <div className="content-item-content">
                            <div className="rbt-default-sidebar-wrapper">
                              <div className="section-title mb--20">
                                <h6 className="rbt-title-style-2">
                                  اهلا {localStorage.getItem("username")}
                                </h6>
                                <a href="" onClick={logout}>
                                  {" "}
                                  تسجيل خروج{" "}
                                </a>
                              </div>
                              <nav className="mainmenu-nav">
                                <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                  <li>
                                    <button
                                      onClick={() => setPage("programs")}
                                      href="#"
                                      style={{
                                        color:
                                          page === "programs" ? "black" : "",
                                      }}
                                      className="side-nav-btn"
                                    >
                                      <span>البرامج</span>
                                      <i className="feather-home"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => setPage("blogs")}
                                      style={{
                                        color: page === "blogs" ? "black" : "",
                                      }}
                                      className="side-nav-btn"
                                    >
                                      <span>المدونة</span>
                                      <i className="feather-user"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => setPage("faqs")}
                                      style={{
                                        color: page === "faqs" ? "black" : "",
                                      }}
                                      className="side-nav-btn"
                                    >
                                      <span>الأسئلة الشائعة</span>
                                      <i className="feather-book-open"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => setPage("jobs")}
                                      style={{
                                        color: page === "jobs" ? "black" : "",
                                      }}
                                      className="side-nav-btn"
                                    >
                                      <span>الوظائف</span>
                                      <i className="feather-bookmark"></i>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 ">
                      <div className="rbt-accordion-area accordion-style-1 bg-color-white mt--30">
                        <div className="container">
                          <div className="row g-5 align-items-center">
                            <div className="col-lg-12 offset-lg-1">
                              <div className="rbt-accordion-style accordion">
                                {showSuccess && (
                                  <Alert variant="success">
                                    تمت العملية بنجاح
                                  </Alert>
                                )}
                                {showError && (
                                  <Alert variant="danger">فشلت العملية</Alert>
                                )}
                                {page === "programs" && (
                                  <ProgramList
                                    toggleError={()=>toggleError()}
                                    toggleSuccess={()=>toggleSuccess()}
                                  />
                                )}
                                {page === "blogs" && (
                                  <BlogList
                                    toggleError={toggleError}
                                    toggleSuccess={toggleSuccess}
                                  />
                                )}
                                {page === "faqs" && (
                                  <FaqList
                                    toggleError={()=>toggleError()}
                                    toggleSuccess={toggleSuccess}
                                  />
                                )}
                                {page === "jobs" && (
                                  <JobList
                                    toggleError={toggleError}
                                    toggleSuccess={toggleSuccess}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
