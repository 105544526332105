import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import HomePage from "./pages/HomePage";
import ProgramsPage from "./pages/ProgramsPage";
import ProgramDetailsPage from "./pages/ProgramDetailsPage";
import BlogsPage from "./pages/BlogsPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import CareersPage from "./pages/CareersPage";
import CareerDetailsPage from "./pages/CareerDetailsPage";
import FAQPage from "./pages/FAQPage";
import AboutPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";
import RegistrationPage from "./pages/RegistrationPage";
import ErrorPage from "./pages/ErrorPage";
import AdminPage from "./pages/AdminPage";

import { Provider } from "react-redux";
import { store } from "./app/store";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "programs",
        element: <ProgramsPage />,
      },
      {
        path: "programs/:id",
        element: <ProgramDetailsPage />,
      },
      {
        path: "blogs",
        element: <BlogsPage />,
      },
      {
        path: "blogs/:id",
        element: <BlogDetailsPage />,
      },
      {
        path: "careers",
        element: <CareersPage />,
      },
      {
        path: "careers/:id",
        element: <CareerDetailsPage />,
      },
      {
        path: "faq",
        element: <FAQPage />,
      },
      {
        path: "contact",
        element: <ContactUsPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "jobRegistration",
        element: <RegistrationPage formType={'job'} />,
      },
      {
        path: "programRegistration",
        element: <RegistrationPage formType={'program'} />,
      },
      {
        path: "adminpage",
        element: <AdminPage />,
      },
    ],
  },
]);

const scriptPaths = [
  "/assets/js/vendor/modernizr.min.js",
  "/assets/js/vendor/jquery.js",
  "/assets/js/vendor/bootstrap.min.js",
  "/assets/js/vendor/sal.js",
  "/assets/js/vendor/swiper.js",
  "/assets/js/vendor/magnify.min.js",
  "/assets/js/vendor/jquery-appear.js",
  "/assets/js/vendor/odometer.js",
  "/assets/js/vendor/backtotop.js",
  "/assets/js/vendor/isotop.js",
  "/assets/js/vendor/imageloaded.js",
  "/assets/js/vendor/wow.js",
  "/assets/js/vendor/waypoint.min.js",
  "/assets/js/vendor/easypie.js",
  "/assets/js/vendor/text-type.js",
  "/assets/js/vendor/jquery-one-page-nav.js",
  "/assets/js/vendor/bootstrap-select.min.js",
  "/assets/js/vendor/jquery-ui.js",
  "/assets/js/vendor/magnify-popup.min.js",
  "/assets/js/vendor/paralax-scroll.js",
  "/assets/js/vendor/paralax.min.js",
  "/assets/js/vendor/countdown.js",
  "/assets/js/vendor/plyr.js",
  "/assets/js/main.js",
];

const loadScriptsWithDelay = (paths, index = 0) => {
  if (index < paths.length) {
    const script = document.createElement("script");
    script.src = paths[index];
    script.onload = () => {
      // Load next script after a delay (e.g., 500 milliseconds)
      setTimeout(() => {
        loadScriptsWithDelay(paths, index + 1);
      }, 0);
    };
    document.body.appendChild(script);
  }
};

// Start loading scripts
loadScriptsWithDelay(scriptPaths);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
