import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProgramRegistrations,
  createProgramRegistration,
} from "../../api/programRegistrationApi";

// Async Thunks for API Calls
export const fetchProgramRegistrationsAsync = createAsyncThunk(
  "programRegistrations/fetchProgramRegistrations",
  async () => {
    const response = await fetchProgramRegistrations();
    return response;
  }
);

export const createProgramRegistrationAsync = createAsyncThunk(
  "programRegistrations/createProgramRegistration",
  async (postData) => {
    const response = await createProgramRegistration(postData);
    return response;
  }
);

// Slice

const programRegistrationsSlice = createSlice({
  name: "programRegistrations",
  initialState: {
    programRegistrations: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Additional reducers can be defined here
  },
  extraReducers: (builder) =>{
    builder
      // Fetch Program Registrations
      .addCase(fetchProgramRegistrationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProgramRegistrationsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programRegistrations = action.payload;
      })
      .addCase(fetchProgramRegistrationsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Create Program Registration
      .addCase(createProgramRegistrationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProgramRegistrationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.programRegistrations.push(action.payload);

      })
      .addCase(createProgramRegistrationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;

      })
  },
});

export default programRegistrationsSlice.reducer;
