// import './App.css';

import { Outlet } from "react-router-dom";
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import Divider from "./components/Divider";
import Copyright from "./sections/Copyright";

function App() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
      <Divider />
      <Copyright />
    </div>
  );
}
export default App;
