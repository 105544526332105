import React from "react";
import { Link } from "react-router-dom";
import FAQAccordionCard from "./FAQAccordionCard";

export default function CareerCard({
  index,
  positionName,
  jobDescription,
  requirements,
  responsibilities,
  gender,
  visible,
}) {
  if (!visible) return null;
  return (
    <div class="col-lg-12">
      <div class="rbt-accordion-style rbt-accordion-01  accordion" >
        <div class="accordion" id="accordionExamplea1">
          <FAQAccordionCard
            faq={{
              question: (
                <div style={{ display: "flex", gap: "3rem" }}>
                  <div>
                    <h4 style={{ display: "-webkit-box" }}>{positionName}</h4>
                    <h6 className="text-end ml--15">{jobDescription}</h6>
                  </div>
                </div>
              ),

              answer: (
                <div class="details">
                  <div class="row">
                    <div class="col-md-6 mb-sm-30">
                      <div class="box-custom">
                        <h4>متطلبات الوظيفة</h4>
                        <ul class="list s1">
                          {requirements.map((req) => (
                            <li>{req}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div class="col-md-6 mb-sm-30">
                      <div class="box-custom">
                        <h4>مهام الوظيفة</h4>
                        <ul class="list s1">
                          {responsibilities.map((res) => (
                            <li>{res}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div class="spacer-single"></div>

                    <div class="about-btn mt--40">
                      <Link
                        class="rbt-btn btn-gradient hover-icon-reverse"
                        to="/jobRegistration"
                      >
                        <span class="icon-reverse-wrapper">
                          <span class="btn-text">التقديم للوظيفة</span>
                          <span class="btn-icon">
                            <i class="feather-arrow-left"></i>
                          </span>
                          <span class="btn-icon">
                            <i class="feather-arrow-left"></i>
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ),
            }}
            index={index}
          />
        </div>
      </div>
    </div>
  );
}
